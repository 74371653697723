import {
   CombinationExplorer,
   ProjectSettings,
   Rewards,
   Variable,
   Variables,
} from "@views/index"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import { requireValidProject } from "../guards/control"
import InstallationInstructions from "@views/InstallationInstructions.vue"
import Overview from "@views/Overview.vue"
import VariableExplorer from "@views/VariableExplorer.vue"
import { useProjectStore } from "@stores/projectStore"

export default [
   {
      name: "Variables",
      path: "/orgs/:orgSlug/projects/:projectId/variables",
      component: Variables,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "CreateVariable",
      path: "/orgs/:orgSlug/projects/:projectId/variables/create",
      component: Variable,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "EditVariable",
      path: "/orgs/:orgSlug/projects/:projectId/variables/:variableId",
      component: Variable,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "Rewards",
      path: "/orgs/:orgSlug/projects/:projectId/rewards",
      component: Rewards,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "ProjectSettings",
      path: "/orgs/:orgSlug/projects/:projectId/settings",
      component: ProjectSettings,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "CombinationExplorer",
      path: "/orgs/:orgSlug/projects/:projectId/combination-explorer",
      component: CombinationExplorer,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "VariableExplorer",
      path: "/orgs/:orgSlug/projects/:projectId/variable-explorer",
      component: VariableExplorer,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "InstallationInstructions",
      path: "/orgs/:orgSlug/projects/:projectId/install",
      component: InstallationInstructions,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      name: "ProjectHome",
      path: "/orgs/:orgSlug/projects/:projectId",
      component: Overview,
      meta: {
         layout: "ProjectLayout",
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         const projectStore = useProjectStore()
         // Set the project ID and load project settings
         projectStore.projectId = Number(to.params.projectId)

         return requireValidProject(to, from, next)
      },
   },
]
