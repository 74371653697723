import { ACTIVE_STATUSES, getOrg } from "@/services/clerk"
import { defineComponent, onMounted } from "vue"
import { useProjectStore } from "@stores/projectStore"

export const ActiveSubscription = defineComponent({
   setup(_props, { slots }) {
      onMounted(async () => {
         const opm = await getOrg()
         if (!opm) {
            return () => null
         }
         return () =>
            opm.publicMetadata.subscriptionStatus &&
            ACTIVE_STATUSES.includes(opm.publicMetadata.subscriptionStatus)
               ? slots.default?.()
               : null
      })
   },
})

export const InactiveSubscription = defineComponent({
   setup(_props, { slots }) {
      onMounted(async () => {
         const opm = await getOrg()
         if (!opm) {
            return () => null
         }
         return () =>
            !opm.publicMetadata.subscriptionStatus ||
            !ACTIVE_STATUSES.includes(opm.publicMetadata.subscriptionStatus)
               ? slots.default?.()
               : null
      })
   },
})

export const HasProject = defineComponent({
   setup(_props, { slots }) {
      const projectStore = useProjectStore()
      return () => (projectStore.projects.length > 0 ? slots.default?.() : null)
   },
})

export const NoProjects = defineComponent({
   setup(_props, { slots }) {
      const projectStore = useProjectStore()
      return () =>
         projectStore.projects.length === 0 ? slots.default?.() : null
   },
})

export const HasProjectLoaded = defineComponent({
   setup(_props, { slots }) {
      const projectStore = useProjectStore()
      return () => (projectStore.projectId ? slots.default?.() : null)
   },
})

export const NoProjectLoaded = defineComponent({
   setup(_props, { slots }) {
      const projectStore = useProjectStore()
      return () => (projectStore.projectId ? null : slots.default?.())
   },
})

// export const RedirectToStripeRenewal = defineComponent(() => {
// TODO: following https://github.com/wobsoriano/@clerk/vue/blob/fad0b6ad3e108121661e29b17d8d8832c25e084d/src/controlComponents.ts#L79C1-L87C3
// })
