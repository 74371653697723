import {
   getOrg,
   hasActiveOrg,
   hasActiveSession,
   hasActiveStripeSubscription,
   clerk,
   EzbotAppJWTPayload,
} from "@/services/clerk"
import { addBreadcrumb } from "../../services/sentry"
import { useProjectStore } from "@stores/projectStore"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import { jwtDecode } from "jwt-decode"

const requireLogin = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating user is logged in for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   return next()
}

const requireActiveSubscription = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating org has active subscription for route ${to.fullPath}`,
      level: "info",
   })
   const activeOrg = await hasActiveOrg()
   if (!activeOrg) {
      return next({ name: "SelectOrg" })
   }

   const activeSubscription = await hasActiveStripeSubscription()
   if (!activeSubscription) {
      return next({ name: "SubscriptionError" })
   }

   return next()
}

const requireValidProject = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating project for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      return next({ name: "SelectOrg" })
   }

   const projectStore = useProjectStore()
   const projectId = to.params.projectId ? Number(to.params.projectId) : null

   // If no projectId in route, handle redirection
   if (!projectId) {
      const org = await getOrg()
      if (!org) {
         throw new Error("No active org found")
      }
      const { slug } = org
      if (!slug) {
         throw new Error("Organization slug not found")
      }

      // If we have a projectId in the store, use that
      if (projectStore.projectId && projectStore.projectId === projectId) {
         return next({
            name: "ProjectHome",
            params: {
               projectId: projectStore.projectId,
               orgSlug: slug,
            },
         })
      }

      // If we have projects in the store, use the first one
      if (projectStore.projects.length > 0) {
         return next({
            name: "ProjectHome",
            params: { projectId: projectStore.projects[0].id, orgSlug: slug },
         })
      }

      // If we don't have projects in the store, fetch them
      await projectStore.getActiveOrgProjects()
      const projects = projectStore.projects

      if (!projects || projects.length === 0) {
         return next({ name: "CreateProject", params: { orgSlug: slug } })
      }

      return next({
         name: "ProjectHome",
         params: { projectId: projects[0].id, orgSlug: slug },
      })
   }

   // Set the current projectId in the store
   projectStore.projectId = projectId

   // If we already have projects in the store, check if the current project exists
   if (projectStore.projects.length > 0) {
      const projectExists = projectStore.projects.some(
         (p) => p.id === projectId,
      )
      if (projectExists) {
         return next()
      }
   }

   // If we don't have projects or the current project doesn't exist in our list,
   // fetch projects from the backend (this will also validate if the user has access)
   try {
      await projectStore.getActiveOrgProjects()
      const projectExists = projectStore.projects.some(
         (p) => p.id === projectId,
      )

      if (!projectExists) {
         // Project doesn't exist or user doesn't have access
         const org = await getOrg()
         if (!org) {
            throw new Error("No active org found")
         }
         const { slug } = org

         if (projectStore.projects.length > 0) {
            return next({
               name: "ProjectHome",
               params: {
                  projectId: projectStore.projects[0].id,
                  orgSlug: slug,
               },
            })
         } else {
            return next({ name: "CreateProject", params: { orgSlug: slug } })
         }
      }

      return next()
   } catch (error) {
      // Handle API errors (e.g., unauthorized)
      const org = await getOrg()
      if (!org) {
         throw new Error("No active org found")
      }
      const { slug } = org

      return next({ name: "OrgHome", params: { orgSlug: slug } })
   }
}

const requireEzbotEmployee = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating user is Ezbot employee for route ${to.fullPath}`,
      level: "info",
   })

   // First check if user is logged in
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   try {
      // In development mode, treat all users as employees if the flag is set
      if (import.meta.env.VITE_MOCK_EMPLOYEE === "true") {
         console.log(
            "Development mode: Treating user as Ezbot employee in route guard",
         )
         return next()
      }

      // Get the user JWT and check for employee status
      const token = await clerk.session?.getToken()

      if (!token) {
         return next({ name: "SignIn" })
      }

      const decoded = jwtDecode(token) as EzbotAppJWTPayload
      const isEmployee = decoded.user_metadata?.isEzbotEmployee === true

      if (!isEmployee) {
         // Redirect to home if not an employee
         return next({ name: "Home" })
      }

      return next()
   } catch (error) {
      // Handle errors by redirecting to home
      return next({ name: "Home" })
   }
}

export {
   requireLogin,
   requireActiveSubscription,
   requireValidProject,
   requireEzbotEmployee,
}
