import { useProjectStore } from "@/stores/projectStore"
import { useOverviewStore } from "@/stores/overviewStore"
import { useAppState } from "@/stores/appState"

/**
 * Clears all application state when switching organizations
 * This ensures a clean slate when moving between organizations
 */
export const clearAllState = () => {
   const projectStore = useProjectStore()
   const overviewStore = useOverviewStore()
   const appState = useAppState()

   // Clear project store state
   projectStore.projectId = null
   projectStore.projectName = null
   projectStore.projects = []
   projectStore.variables = []

   // Clear overview store state
   overviewStore.clearState()

   // Reset app state date range to defaults (optional)
   const resetStartDate = (() => {
      const date = new Date()
      date.setDate(date.getDate() - 30)
      return Math.floor(date.getTime())
   })()

   const resetEndDate = (() => {
      const date = new Date()
      date.setDate(date.getDate() + 1)
      return Math.floor(date.getTime())
   })()

   appState.updateDateRange(resetStartDate, resetEndDate)
}
