<template>
   <QSelect
      :model-value="fieldValue"
      class="col-grow"
      style="min-width: 200px"
      label="UTM Source"
      :options="utmSourceOptions"
      use-input
      hide-selected
      fill-input
      @filter="onUtmSourceFilter"
      @popup-show="loadUtmSources"
      @update:model-value="
         (value) => {
            emit('change', value)
         }
      "
   />
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { useOverviewStore } from "@stores/overviewStore"

const props = defineProps<{
   modelValue: string | null
   startDate: number
   endDate: number
}>()

const emit = defineEmits<{
   // eslint-disable-next-line no-unused-vars
   (e: "change", value: string | null): void
}>()

const overviewStore = useOverviewStore()
const utmSourceOptions = ref<string[]>([])
const fieldValue = ref<string | null>(null)

const onUtmSourceFilter = async (
   val: string,
   // eslint-disable-next-line no-unused-vars
   update: (callback: () => void) => void,
) => {
   if (val === "") {
      const sources = await overviewStore.loadUtmSources(
         props.startDate,
         props.endDate,
      )
      update(() => {
         utmSourceOptions.value = sources
      })
      return
   }

   const sources = await overviewStore.loadUtmSourcesAc(
      props.startDate,
      props.endDate,
      val,
   )
   update(() => {
      utmSourceOptions.value = sources
   })
}

const loadUtmSources = async () => {
   const sources = await overviewStore.loadUtmSources(
      props.startDate,
      props.endDate,
   )
   utmSourceOptions.value = sources
}

// Reload options when dates change
watch(
   () => [props.startDate, props.endDate],
   async () => {
      await loadUtmSources()
   },
)
</script>
