import {
   Unauthorized,
   SignUp,
   SignedOut,
   Authorize,
   SignIn,
   SignOut,
} from "@views/index"
import {
   requireActiveSubscription,
   requireLogin,
   requireValidProject,
} from "../guards/control"
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router"
import Overview from "@views/Overview.vue"

export default [
   {
      name: "Home",
      path: "",
      component: Overview,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireValidProject(to, from, next)
      },
   },
   {
      path: "/sign-in",
      name: "SignIn",
      component: SignIn,
   },
   {
      path: "/sign-in/factor-one",
      name: "SignInFactorOne",
      component: SignIn,
   },
   {
      path: "/sign-in/factor-two",
      name: "SignInFactorOnTwo",
      component: SignIn,
   },
   {
      path: "/sign-in/reset-password",
      name: "SignInPWReset",
      component: SignIn,
   },
   {
      path: "/sign-in/reset-password-success",
      name: "SignInPWResetSuccess",
      component: SignIn,
   },
   {
      path: "/sign-in/sso-callback",
      name: "SignInSSO",
      component: SignIn,
   },
   {
      path: "/sign-in/verify",
      name: "SignInVerify",
      component: SignIn,
   },
   {
      path: "/sign-in/choose",
      name: "SignInChoose",
      component: SignIn,
   },
   {
      path: "/sign-out",
      name: "SignOut",
      component: SignOut,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireLogin(to, from, next)
      },
   },
   {
      path: "/sign-up",
      name: "SignUp",
      component: SignUp,
      meta: {
         layout: "AppLayoutDefault",
      },
   },
   {
      path: "/sign-up/verify-email-address",
      name: "VerifyEmailAddress",
      component: SignUp,
      meta: {
         layout: "AppLayoutDefault",
      },
   },
   {
      path: "/signed-out",
      name: "SignedOut",
      component: SignedOut,
   },
   {
      path: "/unauthorized",
      name: "Unauthorized",
      component: Unauthorized,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireLogin(to, from, next)
      },
   },
   {
      path: "/authorize",
      name: "Authorize",
      component: Authorize,
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         // TODO: Is this right?
         return requireActiveSubscription(to, from, next)
      },
   },
   {
      path: "/checkout/confirmation",
      name: "Confirmation",
      component: () => import("@views/Confirmation.vue"),
      meta: {
         layout: "ConfettiLayout",
      },
   },
]
