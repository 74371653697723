<template>
   <div
      id="top-container"
      class="q-pa-sm bg-white row items-center"
      :class="{ 'sticky-header': sticky }"
   >
      <h1 id="dashboard-title" class="text-h3 text-left text-bold">
         {{ title }}
      </h1>
      <div
         :id="sticky ? 'datepicker-container' : 'dropdown-container'"
         class="row items-center no-wrap q-gutter-md"
      >
         <UtmSourceSelector
            :model-value="modelValue"
            :start-date="startDate"
            :end-date="endDate"
            @change="handleUtmSourceChange"
         />
         <DatePickerSelector
            :start-date="startDate"
            :end-date="endDate"
            @update:dates="handleDateChange"
         />
      </div>
   </div>
</template>

<script setup lang="ts">
import UtmSourceSelector from "./UtmSourceSelector.vue"
import DatePickerSelector from "./DatePickerSelector.vue"

interface Props {
   title: string
   sticky?: boolean
   modelValue: string | null
   startDate: number
   endDate: number
}

interface Emits {
   // eslint-disable-next-line no-unused-vars
   (e: "update:dates", startDate: number, endDate: number): void
   // eslint-disable-next-line no-unused-vars
   (e: "update:modelValue", value: string | null): void
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const handleDateChange = (startDate: number, endDate: number) => {
   emit("update:dates", startDate, endDate)
}

const handleUtmSourceChange = (value: string | null) => {
   emit("update:modelValue", value)
}
</script>

<style scoped lang="scss">
#top-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   gap: 1em;
   h1 {
      text-align: center;
      margin-top: 0.2em;
      margin-bottom: 0.2em;
   }

   &.sticky-header {
      position: sticky;
      top: 74px;
      z-index: 999;
      border-bottom: 1px solid #ccc;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
   }
}
#datepicker-container {
   display: flex;
   gap: 1em;
   max-height: 40px;
}

@media (max-width: 767px) {
   #datepicker-container {
      flex-direction: column;
      align-items: stretch;
   }

   #header {
      h1 {
         font-size: 2rem;
      }
   }
}

@media (min-width: 768px) {
   #header {
      h1 {
         font-size: 3rem;
      }
   }
}
</style>
