<template>
   <QCard class="additional-conversions-card">
      <QCardSection class="bg-primary q-pa-sm">
         <div class="text-h6 text-white">Additional Conversions</div>
      </QCardSection>
      <QCardSection class="q-pa-md content-section">
         <div class="row q-col-gutter-md items-center">
            <div class="col-6 left-section">
               <div
                  v-if="!props.loading"
                  class="conversion-number"
                  :style="cardStyle"
               >
                  <span>{{ formattedNetConversions }}</span>
                  <span class="conversion-number-subtext">conversions</span>
               </div>
               <div v-else>
                  <div
                     class="conversion-number"
                     style="color: darkgrey; outline: darkgrey solid 2px"
                  >
                     <QSkeleton type="text" width="2em" elements="span" />
                     <QSkeleton
                        type="text"
                        width="100%"
                        elements="span"
                        elements-class="conversion-number-subtext"
                     />
                  </div>
               </div>
            </div>
            <div class="col-6">
               <div class="stats-table">
                  <div class="stats-section q-mb-md">
                     <div class="text-weight-bold q-mb-sm">
                        Actual ezbot Conversions
                     </div>
                     <div class="stats-row">
                        <span class="label">Sessions:</span>
                        <span v-if="!props.loading" class="value">{{
                           props.data.ezbot_sessions.toLocaleString()
                        }}</span>
                        <QSkeleton
                           v-else
                           type="text"
                           width="50px"
                           elements="span"
                           elements-class="value"
                        />
                     </div>
                     <div class="stats-row">
                        <span class="label">Conv. Rate:</span>
                        <span v-if="!props.loading" class="value">{{
                           ezbotConversionRate
                        }}</span>

                        <QSkeleton
                           v-else
                           type="text"
                           width="50px"
                           elements="span"
                           elements-class="value"
                        />
                     </div>
                     <div class="stats-row">
                        <span class="label">Conversions:</span>
                        <span v-if="!props.loading" class="value">{{
                           props.data.ezbot_conversions.toLocaleString()
                        }}</span>
                        <QSkeleton
                           v-else
                           type="text"
                           elements="span"
                           elements-class="value"
                           width="50px"
                        />
                     </div>
                  </div>

                  <div class="stats-section">
                     <div class="text-weight-bold q-mb-sm">
                        Projected Conversions w/o ezbot
                     </div>
                     <div class="stats-row">
                        <span class="label">Default Conv. Rate:</span>
                        <span v-if="!props.loading" class="value"
                           >{{
                              (props.data.default_cr * 100).toFixed(2)
                           }}%</span
                        >
                        <QSkeleton
                           v-else
                           type="text"
                           width="50px"
                           elements="span"
                           elements-class="value"
                        />
                     </div>
                     <div class="stats-row">
                        <span class="label">Conversions:</span>
                        <span v-if="!props.loading" class="value">{{
                           defaultConversions.toLocaleString()
                        }}</span>
                        <QSkeleton
                           v-else
                           type="text"
                           width="50px"
                           elements="span"
                           elements-class="value"
                        />
                     </div>
                  </div>
                  <div class="stats-row comparison-result">
                     <span v-if="!props.loading" class="value">{{
                        props.data.ezbot_conversions.toLocaleString()
                     }}</span>
                     <QSkeleton
                        v-else
                        type="text"
                        width="50px"
                        elements="span"
                        elements-class="value"
                     />
                     -
                     <span v-if="!props.loading" class="value">{{
                        defaultConversions.toLocaleString()
                     }}</span>
                     <QSkeleton
                        v-else
                        type="text"
                        width="50px"
                        elements="span"
                        elements-class="value"
                     />
                     =
                     <span v-if="!props.loading" class="value">{{
                        props.data!.addl_conversions.toLocaleString()
                     }}</span>
                     <QSkeleton
                        v-else
                        type="text"
                        width="50px"
                        elements="span"
                        elements-class="value"
                     />
                  </div>
               </div>
            </div>
         </div>
      </QCardSection>
   </QCard>
</template>

<script setup lang="ts">
import { computed, withDefaults } from "vue"
import { QCard, QCardSection } from "quasar"
import { logError } from "@utils/logging"

interface AdditionalConversionsData {
   addl_conversions: number
   ezbot_sessions: number
   ezbot_conversions: number
   default_cr: number
   ezbot_cr: number
}

const props = withDefaults(
   defineProps<{
      data: AdditionalConversionsData
      loading: boolean
   }>(),
   {
      loading: false,
   },
)

const cardStyle = computed(() => {
   const addlConversions = props.data.addl_conversions
   if (addlConversions === 0) {
      return {
         color: "#999", // Neutral color for null values
         outline: "2px solid #999",
      }
   }
   const isPositive = addlConversions > 0
   const color = isPositive ? "#21BA45" : "#C10015"
   return {
      color,
      outline: `2px solid ${color}`,
   }
})

const defaultConversions = computed(() => {
   if (!props.data) {
      return 0
   }
   return Math.round(props.data.ezbot_sessions * props.data.default_cr)
})

const ezbotConversionRate = computed(() => {
   if (!props.data) {
      return "error"
   }
   try {
      return (
         (
            (props.data.ezbot_conversions / props.data.ezbot_sessions) *
            100
         ).toFixed(2) + "%"
      )
   } catch (error) {
      logError(error as Error)
      return "error"
   }
})

const formattedNetConversions = computed(() => {
   if (!props.data) {
      return "error"
   }
   const value = props.data.addl_conversions
   if (value === null) {
      return "N/A"
   }
   if (value > 0) {
      return "+" + value.toLocaleString()
   }
   if (value < 0) {
      return "-" + Math.abs(value).toLocaleString()
   }
   return "0"
})
</script>

<style scoped>
.content-section {
   align-content: center;
   align-items: center;
   vertical-align: center;
   height: 100%;
}

.left-section {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.conversion-number {
   display: flex;
   flex-direction: column;
   align-items: center;
   font-size: 4rem;
   font-weight: 500;
   line-height: 1;
   text-align: center;
   padding: 1rem;
   border-radius: 0.5rem;
}
.conversion-number-subtext {
   font-size: 1.2rem;
   font-weight: 500;
}

.calculation-line {
   font-size: 1.2rem;
   font-weight: 500;
}

.stats-table {
   padding: 0 0.5rem;
}

.stats-row {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 0.25rem;
   font-size: 0.85rem;
}

.stats-row .label {
   color: rgba(0, 0, 0, 0.7);
   margin-right: 1rem;
}

.stats-row .value {
   font-family: monospace;
   font-size: 0.85em;
   font-weight: 500;
}

.stats-row.comparison-result {
   margin-top: 0.5rem;
   padding-top: 0.5rem;
   border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.additional-conversions-card {
   margin-bottom: 20px;
   height: 350px;
   display: flex;
   flex-direction: column;
}
@media (min-width: 768px) {
   .stats-row {
      font-size: 0.95rem;
   }
}
</style>
