import { RouteRecordRaw } from "vue-router"
import NotFound from "@views/NotFound.vue"
import baseRoutes from "./subroutes/baseRoutes"
import onboardingRoutes from "./subroutes/onboardingRoutes"
import orgsRoutes from "./subroutes/orgsRoutes"
import projectsRoutes from "./subroutes/projectsRoutes"
import employeeRoutes from "./subroutes/employeeRoutes"

const routes: RouteRecordRaw[] = [
   ...baseRoutes,
   ...onboardingRoutes,
   ...orgsRoutes,
   ...projectsRoutes,
   ...employeeRoutes,
   {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound,
   },
]

export default routes
