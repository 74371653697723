<template>
   <PageHeader
      v-model="selectedUtmSource"
      title="Overview"
      :start-date="startDate"
      :end-date="endDate"
      :sticky="true"
      @update:dates="handleDateChange"
   />
   <div id="main" class="q-pa-md bg-white q-pt-lg">
      <div class="row q-col-gutter-md">
         <div class="col-12 col-lg-4">
            <ConversionLiftCard
               :loading="overviewLoading"
               :data="
                  overview?.opt_v_default_card ?? {
                     ezbot_overall_rate: 0,
                     ezbot_lb: 0,
                     ezbot_ub: 0,
                     default_overall_rate: 0,
                     default_ub: 0,
                     default_lb: 0,
                  }
               "
            />
         </div>
         <div class="col-12 col-md-6 col-lg-4">
            <AdditionalConversionsCard
               :loading="overviewLoading"
               :data="
                  overview?.addl_conversions_card ?? {
                     addl_conversions: 0,
                     ezbot_sessions: 0,
                     ezbot_conversions: 0,
                     default_cr: 0,
                     ezbot_cr: 0,
                  }
               "
            />
         </div>
         <div class="col-12 col-md-6 col-lg-4">
            <TopConverterServingRatesCard
               :loading="overviewLoading"
               :data="
                  overview?.top_converter_serving_rates_card ?? {
                     segments: [],
                  }
               "
            />
         </div>
      </div>
      <QCard class="graph-card">
         <QCardSection class="bg-primary q-pa-sm">
            <div class="text-h6 text-white">
               ezbot vs Default Conversion Rates
            </div>
         </QCardSection>
         <QCardSection>
            <div id="optimized-vs-default-graph-controls">
               <QToggle
                  v-model="showOptimizedVsDefaultConfidenceIntervals"
                  size="sm"
                  :val="showOptimizedVsDefaultConfidenceIntervals"
                  label="Show 99% Confidence Intervals"
               />
            </div>
            <OptimizedVsDefaultGraph
               :show-confidence-intervals="
                  showOptimizedVsDefaultConfidenceIntervals
               "
               :loading="overviewLoading"
               :data="
                  overview?.opt_v_default_graph_card ?? {
                     dt: [],
                     default_conversion_rate: [],
                     default_lb: [],
                     default_ub: [],
                     ezbot_conversion_rate: [],
                     ezbot_lb: [],
                     ezbot_ub: [],
                  }
               "
            />
         </QCardSection>
      </QCard>
   </div>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed, toRefs } from "vue"
import { useOverviewStore } from "@stores/overviewStore"
import { useAppState } from "@/stores/appState"
import { QCard, QCardSection, QToggle } from "quasar"
import { useQuasar } from "quasar"
import OptimizedVsDefaultGraph from "@components/OptimizedVsDefaultGraph.vue"
import PageHeader from "@/components/common/PageHeader.vue"
import TopConverterServingRatesCard from "@components/TopConverterServingRatesCard.vue"
import ConversionLiftCard from "@components/ConversionLiftCard.vue"
import AdditionalConversionsCard from "../components/AdditionalConversionsCard.vue"

// Utilities
const $q = useQuasar()

const overviewStore = useOverviewStore()
const appState = useAppState()
const showOptimizedVsDefaultConfidenceIntervals = ref(false)
const overviewLoading = ref(false)
const selectedUtmSource = ref<string | null>(null)

// Destructure the overview property from the store to make it accessible in the template
const { overview } = toRefs(overviewStore)

// Use computed properties to access global state
const startDate = computed(() => appState.globalStartDate)
const endDate = computed(() => appState.globalEndDate)

onMounted(async () => {
   overviewLoading.value = true
   await overviewStore.fetchOverview(startDate.value, endDate.value)
   overviewLoading.value = false
})

// Watch for UTM source changes
watch(selectedUtmSource, async (newValue) => {
   overviewLoading.value = true
   await overviewStore.fetchOverview(startDate.value, endDate.value, newValue)
   overviewLoading.value = false
})

// Watch for date range changes from global state
watch(
   [() => appState.globalStartDate, () => appState.globalEndDate],
   async ([newStartDate, newEndDate]) => {
      overviewLoading.value = true
      await overviewStore.fetchOverview(
         newStartDate,
         newEndDate,
         selectedUtmSource.value,
      )
      overviewLoading.value = false
   },
)

const handleDateChange = async (newStartDate: number, newEndDate: number) => {
   overviewLoading.value = true
   // No need to update local refs since we're using computed properties from global state
   await overviewStore.fetchOverview(
      newStartDate,
      newEndDate,
      selectedUtmSource.value,
   )
   overviewLoading.value = false
}

// Subscribe to overviewStore state changes to handle loading
overviewStore.$subscribe((mutation, state) => {
   // Only show loading if we're loading AND don't have data yet
   if (state.loading && !state.overview) {
      $q.loading.show()
   } else {
      $q.loading.hide()
   }
})
</script>
<style scoped lang="scss">
#main {
   min-height: 600px;
   display: flex;
   flex-direction: column;
   gap: 10px;
}
</style>
