<template>
   <QPage padding>
      <div class="q-mb-lg">
         <div class="row items-center q-mb-md">
            <div class="col">
               <div class="text-h4 q-my-none text-weight-medium">
                  Employee Dashboard
               </div>
               <div class="text-subtitle1 text-grey-7">
                  Manage invites and waitlist users
               </div>
            </div>
            <div class="col-auto">
               <QBtn
                  color="primary"
                  icon="mdi-refresh"
                  label="Refresh"
                  @click="refreshData"
               />
            </div>
         </div>
      </div>

      <!-- Waitlist Users Section -->
      <WaitlistUsersList ref="waitlistRef" />
   </QPage>
</template>

<script setup lang="ts">
import WaitlistUsersList from "@/components/admin/WaitlistUsersList.vue"
import { QPage, QBtn } from "quasar"
import { ref } from "vue"

// Reference to the WaitlistUsersList component for refreshing
const waitlistRef = ref<InstanceType<typeof WaitlistUsersList> | null>(null)

// Function to refresh all data on the page
const refreshData = () => {
   // Refresh the waitlist data
   waitlistRef.value?.refreshWaitlist()
}
</script>
