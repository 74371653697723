<template>
   <QCard class="conversion-lift-card">
      <QCardSection class="bg-primary q-pa-sm">
         <div class="text-h6 text-white">Conversion Rate Lift</div>
      </QCardSection>
      <QCardSection>
         <div class="row q-col-gutter-md items-center">
            <!-- Chart Column -->
            <div class="col-12 col-md-8">
               <div class="chart-container">
                  <BarWithErrorBars
                     v-if="!props.loading"
                     :data="chartData"
                     :options="chartOptions"
                     height="180px"
                  />
                  <QSkeleton v-else type="rect" height="180px" width="100%" />
               </div>
            </div>
            <!-- Lift Stats Column -->
            <div class="col-12 col-md-4">
               <div class="lift-stats">
                  <div
                     class="text-h5 text-center"
                     :style="{
                        color: conversionLift > 0 ? '#21BA45' : '#C10015',
                        fontWeight: conversionLift > 0 ? 'bold' : 'normal',
                     }"
                  >
                     <span v-if="!props.loading">{{
                        formatPercentage(conversionLift)
                     }}</span>
                     <QSkeleton
                        v-else
                        type="text"
                        width="100px"
                        height="3em"
                        elements="span"
                        elements-class="text-h5 text-center"
                     />
                  </div>
                  <div class="text-subtitle2 text-grey text-center">
                     Conversion Rate Lift
                  </div>
               </div>
            </div>
         </div>
      </QCardSection>
   </QCard>
</template>

<script setup lang="ts">
import { computed, ComputedRef, withDefaults, defineProps } from "vue"
import { BarWithErrorBars } from "@/types/charthelper"
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
   ChartOptions,
} from "chart.js"
import { OptVDefault } from "@/types/overview"
import "chartjs-chart-error-bars"
import {
   BarWithErrorBar,
   BarWithErrorBarsController,
} from "chartjs-chart-error-bars"

ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
   BarWithErrorBarsController,
   BarWithErrorBar,
)

// Define props
const props = withDefaults(
   defineProps<{
      data: OptVDefault
      loading: boolean
   }>(),
   {
      loading: false,
   },
)

// Format percentage for display
const formatPercentage = (value: number) => {
   if (value < 0) {
      return `${value.toFixed(2)}%`
   }

   return `+${value.toFixed(2)}%`
}

// Calculate conversion lift
const conversionLift = computed(() => {
   if (!props.data) {
      return 0
   }
   const liftRatio =
      (props.data.ezbot_overall_rate / props.data.default_overall_rate - 1) *
      100
   return liftRatio
})

// Chart colors
const colors = {
   ezbot: "#7E00F7",
   default: "#6e6e6e",
}

// Prepare chart data
const chartData = computed(() => {
   if (!props.data) {
      return {
         type: "barWithErrorBars",
         labels: [],
         datasets: [],
      }
   }
   return {
      type: "barWithErrorBars",
      labels: ["Default", "ezbot"],
      datasets: [
         {
            label: "Conversion Rate",
            errorBarColor: "rgba(0,0,0,0.6)",
            errorBarLineWidth: 3,
            errorBarWhiskerLineWidth: 3,
            data: [
               {
                  y: props.data.default_overall_rate * 100,
                  yMin: props.data.default_lb * 100,
                  yMax: props.data.default_ub * 100,
               },
               {
                  y: props.data.ezbot_overall_rate * 100,
                  yMin: props.data.ezbot_lb * 100,
                  yMax: props.data.ezbot_ub * 100,
               },
            ],
            backgroundColor: [colors.default, colors.ezbot],
            borderWidth: 0,
            barPercentage: 0.5,
         },
      ],
   }
})

// Chart options
const chartOptions: ComputedRef<ChartOptions<"barWithErrorBars">> = computed(
   () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
         legend: {
            display: false,
         },
         tooltip: {
            callbacks: {
               label: (context) => {
                  const dataPoint = context.raw as {
                     y: number
                     yMin: number
                     yMax: number
                  }
                  return [
                     `99% UB: ${dataPoint.yMax.toFixed(2)}%`,
                     `Conv. Rate: ${dataPoint.y.toFixed(2)}%`,
                     `99% LB${dataPoint.yMin.toFixed(2)}%]`,
                  ]
               },
            },
         },
      },
      scales: {
         y: {
            beginAtZero: true,
            title: {
               display: true,
               text: "Conversion Rate (%)",
            },
            ticks: {
               callback: (value) => `${value}%`,
            },
         },
      },
   }),
)
</script>

<style scoped lang="scss">
.conversion-lift-card {
   margin-bottom: 20px;
   height: 350px;
   display: flex;
   flex-direction: column;
}

.q-card__section:last-child {
   flex: 1;
   overflow: hidden;
   display: flex;
   flex-direction: column;
}

.row {
   flex: 1;
   margin: 0;
}

.chart-container {
   position: relative;
   height: 100%;
   width: 100%;
}

.lift-stats {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 20px;
   border-left: 1px solid #eee;
}

@media (max-width: 1023px) {
   .conversion-lift-card {
      height: auto;
      min-height: 350px;
   }

   .lift-stats {
      border-left: none;
      border-top: 1px solid #eee;
      //margin-top: 20px;
      //padding: 20px 0;
   }
}
</style>
