<template>
   <QCard flat bordered class="q-mb-md">
      <QCardSection>
         <div class="row items-center justify-between q-mb-sm">
            <div class="text-h6">Waitlist Users</div>
            <div class="row q-gutter-sm">
               <!-- Status filter dropdown -->
               <QSelect
                  v-model="filters.status"
                  :options="[
                     { label: 'All Statuses', value: '' },
                     { label: 'Pending', value: 'pending' },
                     { label: 'Invited', value: 'invited' },
                     { label: 'Completed', value: 'completed' },
                     { label: 'Rejected', value: 'rejected' },
                  ]"
                  outlined
                  dense
                  emit-value
                  map-options
                  options-dense
                  style="min-width: 150px"
                  @update:model-value="resetPaginationAndFetch"
               />

               <!-- Search input -->
               <QInput
                  v-model="filters.query"
                  outlined
                  dense
                  placeholder="Search by email, name..."
                  @keyup.enter="resetPaginationAndFetch"
               >
                  <template #append>
                     <QIcon
                        name="mdi-magnify"
                        class="cursor-pointer"
                        @click="resetPaginationAndFetch"
                     />
                  </template>
               </QInput>

               <!-- Refresh button -->
               <QBtn
                  color="primary"
                  icon="mdi-refresh"
                  :loading="loading"
                  flat
                  dense
                  @click="refreshWaitlist"
               >
                  <QSpinner v-if="loading" color="white" size="1em" />
               </QBtn>
            </div>
         </div>

         <!-- Error message -->
         <QBanner v-if="error" class="bg-negative text-white q-mb-md" rounded>
            <template #avatar>
               <QIcon name="mdi-alert-circle" />
            </template>
            {{ error }}
         </QBanner>

         <!-- Loading state -->
         <div v-if="loading && !users.length" class="flex flex-center q-pa-xl">
            <QSpinner color="primary" size="3em" />
         </div>

         <!-- Empty state -->
         <QBanner v-else-if="!users.length" class="bg-grey-2 q-mb-md" rounded>
            <template #avatar>
               <QIcon name="mdi-information" />
            </template>
            No waitlist users found.
         </QBanner>

         <!-- Users table -->
         <QTable
            v-else
            :rows="users"
            :columns="columns"
            row-key="id"
            flat
            bordered
            :pagination="tablePagination"
            hide-pagination
         >
            <!-- Custom status column -->
            <template #body-cell-status="props">
               <QTd :props="props">
                  <QChip
                     :color="getStatusColor(props.value)"
                     text-color="white"
                     dense
                     square
                  >
                     {{ props.value }}
                  </QChip>
               </QTd>
            </template>

            <!-- Custom name column -->
            <template #body-cell-name="props">
               <QTd :props="props">
                  <div class="text-grey-6 text-italic">Not provided</div>
               </QTd>
            </template>

            <!-- Custom date column -->
            <template #body-cell-createdAt="props">
               <QTd :props="props">
                  {{ formatTimestamp(props.value) }}
               </QTd>
            </template>

            <!-- Custom actions column with invite button -->
            <template #body-cell-actions="props">
               <QTd :props="props">
                  <QBtn
                     color="primary"
                     label="Invite"
                     size="sm"
                     :disable="
                        props.row.status === 'invited' ||
                        props.row.status === 'completed'
                     "
                     @click="openUserModal(props.row)"
                  />
               </QTd>
            </template>
         </QTable>

         <!-- Pagination controls -->
         <div class="row justify-between items-center q-mt-md">
            <div class="text-body2 text-grey-8">
               Showing {{ pagination.offset + 1 }} to
               {{ Math.min(pagination.offset + users.length, totalCount) }} of
               {{ totalCount }} results
            </div>

            <div class="row items-center q-gutter-sm">
               <QSelect
                  v-model="pagination.limit"
                  :options="[
                     { label: '10 per page', value: 10 },
                     { label: '25 per page', value: 25 },
                     { label: '50 per page', value: 50 },
                     { label: '100 per page', value: 100 },
                  ]"
                  outlined
                  dense
                  emit-value
                  map-options
                  options-dense
                  style="min-width: 120px"
                  @update:model-value="resetOffsetAndFetch"
               />

               <QBtn-group flat>
                  <QBtn
                     icon="mdi-chevron-left"
                     color="primary"
                     flat
                     :disable="pagination.offset === 0 || loading"
                     dense
                     @click="previousPage"
                  />
                  <QBtn
                     icon="mdi-chevron-right"
                     color="primary"
                     flat
                     :disable="
                        pagination.offset + pagination.limit >= totalCount ||
                        loading
                     "
                     dense
                     @click="nextPage"
                  />
               </QBtn-group>
            </div>
         </div>
      </QCardSection>
   </QCard>

   <!-- User information modal -->
   <QDialog v-model="showUserModal" persistent>
      <QCard style="min-width: 500px">
         <QCardSection class="row items-center">
            <div class="text-h6">User Information</div>
            <QSpace />
            <QBtn
               v-close-popup
               icon="mdi-close"
               flat
               round
               dense
               @click="closeUserModal"
            />
         </QCardSection>

         <QSeparator />

         <QCardSection v-if="selectedUser">
            <div class="q-mb-md">
               <div class="text-subtitle2">Email</div>
               <div>{{ selectedUser.email_address }}</div>
            </div>

            <div class="q-mb-md">
               <div class="text-subtitle2">Name</div>
               <div class="text-grey-6 text-italic">Not provided</div>
            </div>

            <div v-if="selectedUser.invitation" class="q-mb-md">
               <div class="text-subtitle2">Invitation</div>
               <div>
                  <div>
                     <strong>Status:</strong>
                     {{ selectedUser.invitation?.status }}
                  </div>
                  <div>
                     <strong>Revoked:</strong>
                     {{ selectedUser.invitation?.revoked ? "Yes" : "No" }}
                  </div>
                  <div v-if="selectedUser.invitation?.url">
                     <strong>URL:</strong> {{ selectedUser.invitation?.url }}
                  </div>
               </div>
            </div>

            <div class="q-mb-md">
               <div class="text-subtitle2">Status</div>
               <QChip
                  :color="getStatusColor(selectedUser.status)"
                  text-color="white"
                  dense
                  square
               >
                  {{ selectedUser.status }}
               </QChip>
            </div>

            <div class="q-mb-md">
               <div class="text-subtitle2">Created</div>
               <div>{{ formatTimestamp(selectedUser.created_at) }}</div>
            </div>

            <!-- Price List Section -->
            <div class="q-mb-md">
               <div class="text-subtitle2">Price List</div>

               <!-- Filters for prices -->
               <div class="row q-gutter-sm q-mb-sm">
                  <QSelect
                     v-model="priceFilters.active"
                     :options="[
                        { label: 'All Prices', value: null },
                        { label: 'Active', value: true },
                        { label: 'Inactive', value: false },
                     ]"
                     label="Status"
                     outlined
                     dense
                     emit-value
                     map-options
                     options-dense
                     style="min-width: 120px"
                     @update:model-value="fetchPrices"
                  />

                  <QSelect
                     v-model="priceFilters.type"
                     :options="[
                        { label: 'All Types', value: null },
                        { label: 'Recurring', value: 'recurring' },
                        { label: 'One-time', value: 'one_time' },
                     ]"
                     label="Type"
                     outlined
                     dense
                     emit-value
                     map-options
                     options-dense
                     style="min-width: 120px"
                     @update:model-value="fetchPrices"
                  />

                  <QBtn
                     color="primary"
                     icon="mdi-refresh"
                     :loading="loadingPrices"
                     flat
                     dense
                     @click="fetchPrices"
                  >
                     <QSpinner v-if="loadingPrices" color="white" size="1em" />
                  </QBtn>
               </div>

               <!-- Loading state -->
               <div v-if="loadingPrices" class="flex flex-center q-pa-md">
                  <QSpinner color="primary" size="2em" />
               </div>

               <!-- Error message -->
               <QBanner
                  v-if="priceError"
                  class="bg-negative text-white q-mb-md"
                  rounded
               >
                  <template #avatar>
                     <QIcon name="mdi-alert-circle" />
                  </template>
                  {{ priceError }}
               </QBanner>

               <!-- Empty state -->
               <QBanner
                  v-else-if="!loadingPrices && !prices.length"
                  class="bg-grey-2 q-mb-md"
                  rounded
               >
                  <template #avatar>
                     <QIcon name="mdi-information" />
                  </template>
                  No prices found.
               </QBanner>

               <!-- Price list table -->
               <QTable
                  v-else
                  v-model:selected="selectedPrice"
                  :rows="prices"
                  :columns="priceColumns"
                  row-key="id"
                  flat
                  bordered
                  :pagination="{ rowsPerPage: 0 }"
                  selection="single"
               >
                  <!-- Custom active column -->
                  <template #body-cell-active="props">
                     <QTd :props="props">
                        <QChip
                           :color="props.value ? 'positive' : 'grey'"
                           text-color="white"
                           dense
                           square
                        >
                           {{ props.value ? "Active" : "Inactive" }}
                        </QChip>
                     </QTd>
                  </template>

                  <!-- Custom price column -->
                  <template #body-cell-unit_amount="props">
                     <QTd :props="props">
                        {{ formatCurrency(props.value, props.row.currency) }}
                     </QTd>
                  </template>

                  <!-- Custom recurring column -->
                  <template #body-cell-recurring="props">
                     <QTd :props="props">
                        <div
                           v-if="
                              props.row.type === 'recurring' &&
                              props.row.recurring
                           "
                        >
                           {{ props.row.recurring.interval_count }}
                           {{ props.row.recurring.interval
                           }}{{
                              props.row.recurring.interval_count > 1 ? "s" : ""
                           }}
                        </div>
                        <div v-else>-</div>
                     </QTd>
                  </template>
               </QTable>

               <!-- Selected price actions -->
               <div v-if="selectedPrice.length" class="row justify-end q-mt-md">
                  <QBtn
                     color="primary"
                     label="Assign to User"
                     :loading="assigningPrice"
                     @click="assignPriceToUser"
                  />
               </div>
            </div>
         </QCardSection>

         <QCardSection class="q-pt-none" align="right">
            <QBtn
               v-close-popup
               label="Close"
               color="primary"
               flat
               @click="closeUserModal"
            />
         </QCardSection>
      </QCard>
   </QDialog>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from "vue"
import {
   getWaitlistUsers,
   getStripePrices,
   StripeQueryParams,
} from "@/services/adminBackendClient"
import { WaitlistUser } from "@/types"
import type { StripePrice } from "@/types/stripe.d"
import {
   QCard,
   QCardSection,
   QTable,
   QTableColumn,
   QTd,
   QBtn,
   QIcon,
   QInput,
   QSelect,
   QBanner,
   QSpinner,
   QChip,
   QDialog,
   QSpace,
   QSeparator,
} from "quasar"

const users = ref<WaitlistUser[]>([])
const loading = ref(false)
const error = ref("")
const totalCount = ref(0)

// Table columns definition
const columns: QTableColumn[] = [
   {
      name: "email",
      label: "Email",
      field: "email_address",
      align: "left",
      sortable: true,
   },
   {
      name: "name",
      label: "Name",
      field: () => "-",
      align: "left",
   },
   { name: "status", label: "Status", field: "status", align: "left" },
   {
      name: "createdAt",
      label: "Created",
      field: "created_at",
      align: "left",
      format: (val) => formatTimestamp(val),
   },
   { name: "actions", label: "Actions", field: "id", align: "center" },
]

// Pagination state
const pagination = reactive({
   limit: 10,
   offset: 0,
})

// Table pagination (just for display, actual pagination handled by API)
const tablePagination = reactive({
   rowsPerPage: 0, // 0 means all rows
})

// Filters state
const filters = reactive({
   query: "",
   status: "" as "" | "pending" | "invited" | "completed" | "rejected",
   order_by: "-created_at",
})

// User modal state
const showUserModal = ref(false)
const selectedUser = ref<WaitlistUser | null>(null)

// Price list state
const prices = ref<StripePrice[]>([])
const loadingPrices = ref(false)
const priceError = ref("")
const selectedPrice = ref<StripePrice[]>([])
const assigningPrice = ref(false)

// Price filters
const priceFilters = reactive({
   active: true as boolean | null,
   type: null as "recurring" | "one_time" | null,
   currency: "usd",
})

// Price table columns
const priceColumns: QTableColumn[] = [
   {
      name: "nickname",
      label: "Name",
      field: "nickname",
      align: "left",
   },
   {
      name: "unit_amount",
      label: "Price",
      field: "unit_amount",
      align: "right",
   },
   {
      name: "recurring",
      label: "Billing Cycle",
      field: "recurring",
      align: "left",
   },
   {
      name: "active",
      label: "Status",
      field: "active",
      align: "center",
   },
]

const getStatusColor = (status: string) => {
   switch (status) {
      case "completed":
         return "positive"
      case "pending":
         return "warning"
      case "invited":
         return "info"
      case "rejected":
         return "negative"
      default:
         return "grey"
   }
}

const openUserModal = (user: WaitlistUser) => {
   selectedUser.value = user
   showUserModal.value = true
   fetchPrices()
}

const closeUserModal = () => {
   showUserModal.value = false
   selectedPrice.value = []
   prices.value = []
}

const fetchWaitlistUsers = async () => {
   loading.value = true
   error.value = ""

   try {
      const response = await getWaitlistUsers({
         limit: pagination.limit,
         offset: pagination.offset,
         query: filters.query || undefined,
         status: filters.status || undefined,
         order_by: filters.order_by,
      })

      users.value = response.data.data
      totalCount.value = response.data.total_count
   } catch (err) {
      console.error("Error fetching waitlist users:", err)
      error.value = "Failed to load waitlist users. Please try again."
   } finally {
      loading.value = false
   }
}

const refreshWaitlist = () => {
   fetchWaitlistUsers()
}

const resetPaginationAndFetch = () => {
   pagination.offset = 0
   fetchWaitlistUsers()
}

const resetOffsetAndFetch = () => {
   pagination.offset = 0
   fetchWaitlistUsers()
}

const nextPage = () => {
   if (pagination.offset + pagination.limit < totalCount.value) {
      pagination.offset += pagination.limit
      fetchWaitlistUsers()
   }
}

const previousPage = () => {
   pagination.offset = Math.max(0, pagination.offset - pagination.limit)
   fetchWaitlistUsers()
}

const formatTimestamp = (timestamp: number) => {
   if (!timestamp) return "Unknown"

   // Convert to milliseconds if in seconds
   const timestampMs = timestamp < 10000000000 ? timestamp * 1000 : timestamp

   const date = new Date(timestampMs)
   return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
   })
}

const formatCurrency = (amount: number, currency: string) => {
   const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency.toUpperCase(),
      minimumFractionDigits: 2,
   })

   return formatter.format(amount / 100)
}

const fetchPrices = async () => {
   loadingPrices.value = true
   priceError.value = ""
   selectedPrice.value = []

   try {
      const params: StripeQueryParams = {}

      if (priceFilters.active !== null) {
         params.active = priceFilters.active
      }

      if (priceFilters.type !== null) {
         params.type = priceFilters.type
      }

      if (priceFilters.currency) {
         params.currency = priceFilters.currency
      }

      const response = await getStripePrices(params)
      prices.value = response.data.data
   } catch (err) {
      console.error("Error fetching prices:", err)
      priceError.value = "Failed to load prices. Please try again."
   } finally {
      loadingPrices.value = false
   }
}

const assignPriceToUser = async () => {
   if (!selectedUser.value || !selectedPrice.value.length) return

   assigningPrice.value = true

   try {
      // TODO: This would be implemented in the final application
      // For now, we'll just simulate a successful assignment
      console.log(
         `Assigning price ${selectedPrice.value[0].id} to user ${selectedUser.value.id}`,
      )

      // Show success message (in the final app, you'd want to use a toast or notification)
      alert(
         `Successfully assigned ${
            selectedPrice.value[0].nickname || "price"
         } to ${selectedUser.value.email_address}`,
      )

      // Clear selection
      selectedPrice.value = []
   } catch (err) {
      console.error("Error assigning price to user:", err)
      priceError.value = "Failed to assign price to user. Please try again."
   } finally {
      assigningPrice.value = false
   }
}

onMounted(() => {
   fetchWaitlistUsers()
})

// Expose methods for parent component
defineExpose({
   refreshWaitlist,
})
</script>
