<template>
   <div id="combinations-table-container">
      <QCard>
         <QCardSection class="bg-primary q-pa-sm">
            <div class="text-h6 text-white">
               Observed Combinations
               <HeaderTooltip
                  tooltip-text="Combinations with at least one session in the current project version. If you have more combinations, expect to see more of them after the next analytics run."
               />
            </div>
         </QCardSection>
         <QCardSection>
            <QTable
               v-if="props.hasVariants && projectStore.variables && pagination"
               id="combinations-table"
               v-model:pagination="pagination"
               dense
               :rows="rows"
               :columns="columns"
               row-key="id"
               width="100%"
               binary-state-sort
               flat
               :loading="loading"
               :rows-per-page-options="[10, 25, 50]"
               @request="onRequest"
            >
               <template #header-cell-conversion_rate="p">
                  <QTh :props="p">
                     {{ longWindowConversionRateColumnLabel }}
                  </QTh>
               </template>
               <template #header-cell-cr_improvement="p">
                  <QTh :props="p">
                     {{ improvementColumnLabel }}
                  </QTh>
               </template>
               <template #header-cell-statistical-significance="p">
                  <QTh :props="p">
                     {{ longWindowStatSigColumnLabel }}
                  </QTh>
               </template>
               <template #header-cell="p">
                  <QTh :props="p">
                     {{ p.col.label }}
                  </QTh>
               </template>
               <template #body-cell-statistical-significance="p: unknown">
                  <QTd>
                     <StatSigRenderer
                        :val="(p as QTableStatSigPropPatch).value"
                     />
                  </QTd>
               </template>

               <template #body-cell-conversions="p: unknown">
                  <QTd>
                     <IntegerRenderer
                        :val="(p as QTableIntegerPropPatch).value"
                     />
                  </QTd>
               </template>
               <template #body-cell-id="p: unknown">
                  <QTd>
                     <CombinationNumberRenderer
                        :val="(p as QTableCombinationNumberPropPatch).value"
                     />
                  </QTd>
               </template>
               <template #body-cell="p: unknown">
                  <QTd
                     ><TruncatedRenderer
                        :val="String((p as QTableTruncatedPropPatch).value)"
                  /></QTd>
               </template>
               <template #body-cell-moving_average_3="p: unknown">
                  <QTd>
                     <ImprovementRenderer
                        :val="(p as QTableImprovementPropPatch).value"
                        :comparison-val="
                           (p as QTableImprovementPropPatch).row
                              .moving_average_7 || 0
                        "
                        tooltip-text="Compared to 7-day moving average"
                     />
                  </QTd>
               </template>
               <template #body-cell-moving_average_7="p: unknown">
                  <QTd>
                     <ImprovementRenderer
                        :val="(p as QTableImprovementPropPatch).value"
                        :comparison-val="
                           (p as QTableImprovementPropPatch).row
                              .moving_average_14 || 0
                        "
                        tooltip-text="Compared to 14-day moving average"
                     />
                  </QTd>
               </template>
               <template #body-cell-moving_average_14="p: unknown">
                  <QTd>
                     <ImprovementRenderer
                        :val="(p as QTableImprovementPropPatch).value"
                        :comparison-val="
                           (p as QTableImprovementPropPatch).row
                              .moving_average_30 || 0
                        "
                        tooltip-text="Compared to 30-day moving average"
                     />
                  </QTd>
               </template>
               <template #no-data="{ icon, filter }">
                  <div
                     id="no-data-container"
                     class="full-width row flex-center text-accent q-ma-lg"
                  >
                     <QIcon
                        size="2em"
                        color="primary"
                        :name="filter ? 'filter_b_and_w' : icon"
                     />
                     <span id="no-data"
                        >No data match your query. Please change your filters
                        and try again.
                     </span>
                  </div>
               </template>
            </QTable>
            <CombinationsTablePlaceholderV2 v-else />
         </QCardSection>
      </QCard>
   </div>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, defineEmits } from "vue"
import { QTable, QTableColumn, QTableProps } from "quasar"
import CombinationsTablePlaceholderV2 from "./CombinationsTablePlaceholderV2.vue"
import {
   CombinationForRender,
   StatSigForRender,
} from "@/types/combinations-table"
import TruncatedRenderer from "./TruncatedRenderer.vue"
import StatSigRenderer from "./StatSigRenderer.vue"
import CombinationNumberRenderer from "./CombinationNumberRenderer.vue"
import {
   formatPercentTwoDecimals,
   formatPercentTwoDecimalsWithSign,
} from "./formatters"
import IntegerRenderer from "@components/combinations-table/IntegerRenderer.vue"
import HeaderTooltip from "@components/combinations-table/HeaderTooltip.vue"
import ImprovementRenderer from "@components/combinations-table/ImprovementRenderer.vue"
import { CombinationStats, TopN } from "@/types"
import { useProjectStore } from "@/stores"

interface Props {
   topN: TopN | undefined
   stats:
      | {
           default_overall_rate?: number
           sessions?: number
        }
      | undefined
   daysBack: number
   hasVariants: boolean
   initialSortBy?: string
   initialSortDesc?: boolean
}

const props = defineProps<Props>()

const projectStore = useProjectStore()

export interface TablePagination {
   page: number
   rowsPerPage: number
   sortBy: string
   descending: boolean
}

interface RequestProps {
   pagination: TablePagination
   filter?: unknown
   // eslint-disable-next-line no-unused-vars
   getCellValue?: (col: unknown, row: unknown) => unknown
}

const emit = defineEmits<{
   // eslint-disable-next-line no-unused-vars
   (e: "get-combos", pagination: TablePagination): void
}>()

const loading = ref(false)

type QTableStatSigPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: StatSigForRender
}
type QTableTruncatedPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string | number
}
type QTableCombinationNumberPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string | number
}
type QTableIntegerPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: string | number
}
type QTableImprovementPropPatch = {
   [K in keyof QTableProps]: QTableProps[K]
} & {
   value: number
   row: CombinationForRender
}

// Initialize pagination with props
const pagination = ref<TablePagination & { rowsNumber: number }>({
   sortBy: props.initialSortBy || "traffic_pct",
   descending: props.initialSortDesc ?? true,
   page: 1,
   rowsPerPage: 10,
   rowsNumber: props.topN?.total_combos ?? 0,
})

const lookBackWindowDays = computed(() => {
   if (!props.daysBack) {
      return 30
   }
   return props.daysBack
})

const idColumn: QTableColumn = {
   name: "id",
   label: "#",
   align: "left",
   field: (row: CombinationForRender) => row.number,
   sortable: true,
}

const sessionsColumn: QTableColumn = {
   name: "sessions",
   label: "Window Sessions",
   align: "left",
   field: (row: CombinationForRender) => row.sessions,
   sortable: true,
}

const conversionsColumn: QTableColumn = {
   name: "conversions",
   label: "Window Conversions",
   align: "left",
   field: (row: CombinationForRender) => row.conversions,
   sortable: true,
}

const longWindowTrafficDistributionColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day Traffic %`
})

const longWindowConversionRateColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day CR`
})

const movingAverage3ConversionRateColumn: QTableColumn = {
   name: "moving_average_3",
   label: "3-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_3,
   sortable: true,
}

const movingAverage7ConversionRateColumn: QTableColumn = {
   name: "moving_average_7",
   label: "7-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_7,
   sortable: true,
}

const movingAverage14ConversionRateColumn: QTableColumn = {
   name: "moving_average_14",
   label: "14-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_14,
   sortable: true,
}

const movingAverage30ConversionRateColumn: QTableColumn = {
   name: "moving_average_30",
   label: "30-Day CR",
   align: "left",
   field: (row: CombinationForRender) => row.moving_average_30,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const longWindowConversionRateColumn: QTableColumn = {
   name: "conversion_rate",
   label: longWindowConversionRateColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.conversionRate,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const improvementColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day CR Δ`
})

const longWindowConversionRateImprovementColumn: QTableColumn = {
   name: "cr_improvement",
   label: improvementColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.conversionRateImprovement,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimalsWithSign(v),
}

const longWindowStatSigColumnLabel = computed(() => {
   return `${lookBackWindowDays.value}-day 99% Confidence Interval`
})

const longWindowStatSigColumn: QTableColumn = {
   name: "statistical-significance",
   label: longWindowStatSigColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.statSig,
   sortable: false,
}

const longWindowTrafficDistributionColumn: QTableColumn = {
   name: "traffic_pct",
   label: longWindowTrafficDistributionColumnLabel.value,
   align: "left",
   field: (row: CombinationForRender) => row.trafficDistribution,
   sortable: true,
   format: (v: number) => formatPercentTwoDecimals(v),
}

const columns = computed(() => {
   const variables = projectStore.variables
   const daysBackValue = props.daysBack ?? 30

   const baseColumns = [idColumn, sessionsColumn, conversionsColumn]

   // Only add moving average columns if we have enough days of data
   if (daysBackValue >= 3) {
      baseColumns.push(movingAverage3ConversionRateColumn)
   }
   if (daysBackValue >= 7) {
      baseColumns.push(movingAverage7ConversionRateColumn)
   }
   if (daysBackValue >= 14) {
      baseColumns.push(movingAverage14ConversionRateColumn)
   }
   if (daysBackValue >= 30) {
      baseColumns.push(movingAverage30ConversionRateColumn)
   }

   // Create dynamic columns with reactive labels
   const dynamicColumns = [
      {
         ...longWindowConversionRateColumn,
         label: longWindowConversionRateColumnLabel.value,
      },
      {
         ...longWindowConversionRateImprovementColumn,
         label: improvementColumnLabel.value,
      },
      {
         ...longWindowTrafficDistributionColumn,
         label: longWindowTrafficDistributionColumnLabel.value,
      },
      {
         ...longWindowStatSigColumn,
         label: longWindowStatSigColumnLabel.value,
      },
   ]

   // Add remaining columns
   baseColumns.push(
      ...dynamicColumns,
      ...variables.map((variable) => {
         return <QTableColumn>{
            name: variable.humanReadableName,
            label: variable.humanReadableName,
            align: "left",
            field: (row: CombinationForRender) =>
               row[variable.humanReadableName],
            sortable: false, // would have to be sorted server-side
            style: "max-width: 400px; text-wrap: wrap;",
         }
      }),
   )
   return baseColumns
})

const calculateConversionRateImprovement = (
   combinationConversionRate: number | undefined,
   defaultConversionRate: number,
) => {
   if (combinationConversionRate) {
      return (
         (combinationConversionRate - defaultConversionRate) /
         defaultConversionRate
      )
   }

   return 0
}

const defaultConversionRate = computed(() => {
   // Prefer the one from stats for now, for the sake of Dashboard.vue
   return props.stats?.default_overall_rate ?? props.topN?.default_cr ?? 0
})

const rows = computed(() => {
   const combinationStats = props.topN?.combinations
   if (!combinationStats) {
      return []
   }
   let agg: CombinationForRender[] = []
   combinationStats.forEach((combinationStat: CombinationStats) => {
      const combinationNumber = combinationStat.id || 0
      const trafficPercent = props.stats?.sessions
         ? combinationStat.sessions / props.stats.sessions
         : combinationStat.traffic_pct || 0
      const combo = projectStore.decodeCombination(combinationNumber)
      const newComboForRender = <CombinationForRender>{
         number: combinationNumber,
         conversionRate: combinationStat.conversion_rate
            ? combinationStat.conversion_rate
            : 0,
         window_cr: combinationStat.window_cr || 0,
         moving_average_3: combinationStat.moving_average_3 || 0,
         moving_average_7: combinationStat.moving_average_7 || 0,
         moving_average_14: combinationStat.moving_average_14 || 0,
         moving_average_30: combinationStat.moving_average_30 || 0,
         trafficDistribution: trafficPercent,
         conversionRateImprovement:
            props.stats?.default_overall_rate !== undefined
               ? calculateConversionRateImprovement(
                    combinationStat.conversion_rate,
                    defaultConversionRate.value,
                 )
               : combinationStat.cr_improvement ?? 0,
         statSig: {
            lb: combinationStat.cr_lb ? combinationStat.cr_lb : 0,
            ub: combinationStat.cr_ub ? combinationStat.cr_ub : 0,
            defaultConversionRate: defaultConversionRate.value,
         },
         conversions: combinationStat.conversions
            ? combinationStat.conversions
            : 0,
         sessions: combinationStat.sessions,
      }
      combo.forEach((variable) => {
         newComboForRender[variable.humanReadableName] = variable.value
      })

      agg.push(newComboForRender)
   })
   return agg
})

const onRequest = async (props: RequestProps) => {
   const { pagination: newPagination } = props
   loading.value = true

   try {
      // Update local pagination state first to ensure UI updates immediately
      pagination.value = {
         ...pagination.value,
         page: newPagination.page,
         rowsPerPage: newPagination.rowsPerPage,
         sortBy: newPagination.sortBy,
         descending: newPagination.descending,
      }

      await emit("get-combos", newPagination)
   } finally {
      loading.value = false
   }
}
</script>
<style lang="scss" scoped>
#combinations-table-container {
   margin-top: 20px;
   margin-bottom: 20px;
   width: 100%;
   .q-td {
      text-align: left;
   }
}
.toggles-filters-container {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   // border: 1px solid #e0e0e0;
   // border-radius: 5px;
}
#no-data {
   font-size: 1.25em;
   color: $primary;
   font-weight: bold;
   margin-left: 10px;
}
#no-data-container {
   min-height: 30vh;
}
</style>
