<template>
   <div></div>
</template>
<script setup lang="ts">
import { onMounted } from "vue"
import { useClerk } from "@clerk/vue"
import { useRouter } from "vue-router"
import { clearAllState } from "@/utils/stateManagement"

// When a user visits /sign-out, this view loads
// and signs the user out before redirecting them to /signed-out
const clerk = useClerk()
const router = useRouter()

onMounted(async () => {
   try {
      // First clear all application state
      clearAllState()

      // Then sign out from Clerk
      await clerk.value?.signOut()

      // Finally navigate to the signed out page
      router.push({ name: "SignedOut" })
   } catch (error) {
      console.error("Error during sign out:", error)
      // Still try to navigate to signed out page even if there's an error
      router.push({ name: "SignedOut" })
   }
})
</script>
