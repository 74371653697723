import {
   getNumberOfOrgs,
   getOrg,
   hasActiveOrg,
   hasActiveSession,
   hasActiveStripeSubscription,
   refreshToken,
   setFirstOrgAsActive,
} from "@/services/clerk"
import { addBreadcrumb } from "@/services/sentry"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"

const checkOnboardingStepTwo = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating onboarding step two for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   // TODO: Only allow users to create org if they are the subscription holder
   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      return next()
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      if (numOrgs >= 1) {
         await setFirstOrgAsActive()
      }
   }

   // an activeSubscription is determined by the presence of a stripe subscription
   // on the Clerk Organization's Public Metadata. Because we need this in the session token,
   // we need to call for a fresh token here to ensure we have the latest data.
   await refreshToken()
   const org = await getOrg()
   if (!org) {
      return next({ name: "SubscriptionError" })
   }

   const { slug } = org
   const activeSubscription = await hasActiveStripeSubscription()

   if (activeSubscription) {
      return next({
         name: "OrgHome",
         params: { orgSlug: slug },
      })
   }

   return next({
      name: "SubscriptionError",
   })
}

const verifySubscription = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating subscription for route ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }

   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      return next({ name: "OnboardingCreateOrg" })
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      if (numOrgs >= 1) {
         await setFirstOrgAsActive()
      }
   }

   // an activeSubscription is determined by the presence of a stripe subscription
   // on the Clerk Organization's Public Metadata. Because we need this in the session token,
   // we need to call for a fresh token here to ensure we have the latest data.
   await refreshToken()
   const org = await getOrg()
   if (!org) {
      return next()
   }

   const { slug } = org
   const activeSubscription = await hasActiveStripeSubscription()

   if (activeSubscription) {
      return next({
         name: "OrgHome",
         params: { orgSlug: slug },
      })
   }

   return next()
}

export { checkOnboardingStepTwo, verifySubscription }
