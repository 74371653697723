<template>
   <div id="graphs-container">
      <QCard class="graph-card">
         <QCardSection class="bg-primary">
            <div class="text-h5 text-white">Performance</div>
         </QCardSection>
         <QCardSection>
            <div v-if="trafficDistributionData">
               <TrafficDistributionGraph
                  :chart-data="trafficDistributionData"
                  :show-legend="true"
                  height="300px"
               />
            </div>
            <div v-if="conversionRateData">
               <ConversionRateGraph
                  :chart-data="conversionRateData"
                  :show-legend="false"
                  :show-conversion-rate-improvement="false"
                  height="400px"
               />
            </div>
         </QCardSection>
      </QCard>
   </div>
</template>
<script setup lang="ts">
import { computed } from "vue"
import TrafficDistributionGraph from "./TrafficDistributionGraph.vue"
import ConversionRateGraph from "./conversion-rate-graph/ConversionRateGraph.vue"
import { ChartDataset, Point } from "chart.js"
import { getApexColor } from "@utils/chartColors"
import { TimeSeries, ConversionTimeSeries } from "@/types/combination-explorer"

const props = defineProps<{
   timeSeries: TimeSeries | null
   conversionTimeSeries: ConversionTimeSeries | null
}>()

// Transform the timeSeries data into a format suitable for the stacked graph
const trafficDistributionData = computed(() => {
   if (!props.timeSeries?.action_time_series) return null

   // Get all unique timestamps across all series
   const allTimestamps = Array.from(
      new Set(
         props.timeSeries.action_time_series.flatMap(
            (series) => series.timestamps,
         ),
      ),
   ).sort((a, b) => a - b)

   // Initialize datasets
   const datasets: ChartDataset<"line", Point[]>[] =
      props.timeSeries.action_time_series.map((series) => ({
         label:
            series.action === -1
               ? "Other Combinations"
               : `Combination ${series.action}`,
         data: [], // Will fill with {x, y} points
         fill: "origin",
         borderColor: getApexColor(series.action),
         backgroundColor: getApexColor(series.action),
         pointRadius: 1,
         showLine: true,
         tension: 0.4,
      }))

   // For each timestamp, calculate the percentage for each action
   allTimestamps.forEach((timestamp) => {
      // Get total count for this timestamp
      const totalCount = props.timeSeries!.action_time_series.reduce(
         (sum, series) => {
            const idx = series.timestamps.indexOf(timestamp)
            return sum + (idx !== -1 ? series.counts[idx] : 0)
         },
         0,
      )

      // Calculate percentage for each series
      props.timeSeries!.action_time_series.forEach((series, index) => {
         const idx = series.timestamps.indexOf(timestamp)
         const count = idx !== -1 ? series.counts[idx] : 0
         const percentage = totalCount > 0 ? (count / totalCount) * 100 : 0
         datasets[index].data.push({
            x: timestamp * 1000,
            y: percentage,
         })
      })
   })

   const result = {
      datasets,
   }
   return result
})

// Transform the conversion time series data
const conversionRateData = computed(() => {
   if (!props.conversionTimeSeries?.action_time_series) return null

   // Get all unique timestamps across all series
   const allTimestamps = Array.from(
      new Set(
         props.conversionTimeSeries.action_time_series.flatMap(
            (series) => series.timestamps,
         ),
      ),
   ).sort((a, b) => a - b)

   // Initialize datasets
   const datasets: ChartDataset<"line", Point[]>[] =
      props.conversionTimeSeries.action_time_series.map((series) => ({
         label:
            series.action === -1
               ? "Other Combinations"
               : `Combination ${series.action}`,
         data: [], // Will fill with {x, y} points
         fill: false,
         borderColor: getApexColor(series.action),
         backgroundColor: getApexColor(series.action),
         tension: 0.4,
         pointRadius: 0,
      }))

   // For each timestamp, get the conversion rate for each action
   allTimestamps.forEach((timestamp) => {
      props.conversionTimeSeries!.action_time_series.forEach(
         (series, index) => {
            const idx = series.timestamps.indexOf(timestamp)
            const rate =
               idx !== -1 && series.conversion_rates
                  ? series.conversion_rates[idx] * 100 // Convert to percentage
                  : null
            if (rate !== null) {
               datasets[index].data.push({
                  x: timestamp * 1000,
                  y: rate,
               })
            }
         },
      )
   })

   const result = {
      datasets,
   }
   return result
})
</script>
<style lang="scss" scoped>
#graphs-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 2em;
}
.graph-card {
   width: 100%;
}
#graph-controls {
   display: flex;
   justify-content: flex-start;
   margin-bottom: 2em;
   gap: 1em;
   align-items: center;
}
</style>
