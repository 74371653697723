<template>
   <QCard>
      <QCardSection class="text-center">
         <h1 id="headline">
            You have insufficient permissions to view this page.
         </h1>
         <p>You have the following role: {{ orgRole }}</p>
         <p>You have the following orgId: {{ orgId }}</p>
      </QCardSection>
   </QCard>
</template>
<script setup lang="ts">
import { QCard } from "quasar"
import { useAuth } from "@clerk/vue"
const { orgRole, orgId } = useAuth()
</script>
<style lang="scss">
#headline {
   color: $primary;
}
</style>
