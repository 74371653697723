<template>
   <QForm id="variable-edit-form" ref="form" @submit="handleSubmit">
      <q-input
         v-model="humanReadableName"
         filled
         label="Human Readable Name"
         lazy-rules
         :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />
      <div id="action-selector">
         <QSelect
            v-model="actionOption"
            :options="actionOptions"
            label="Action"
            required
         ></QSelect>
      </div>
      <q-input
         v-if="showCSSSelectorInput"
         v-model="selector"
         filled
         label="CSS Selector"
         lazy-rules
         :rules="[validateSelector]"
      />
      <q-input
         v-if="actionValue == 'setAttribute'"
         v-model="attribute"
         filled
         label="Attribute"
         lazy-rules
         :rules="[validateAttribute]"
      />
      <AllowedValuesField
         ref="allowedValuesField"
         :allowed-values="allowedValues"
         :allowed-value-type="allowedValueType"
         @added-allowed-value="updateAllowedValues"
         @removed-allowed-value="updateAllowedValues"
         @set-default-value="updateAllowedValues"
      />
      <div
         id="submit-row"
         :class="{
            row: true,
            edit: mode == 'edit',
            create: mode == 'create',
         }"
      >
         <QBtn
            v-if="mode == 'edit'"
            color="negative"
            @click="handleDeleteVariable(variableId)"
            ><QIcon name="mdi-delete"
         /></QBtn>
         <QBtn
            id="submit"
            :disabled="showDisabledSubmit"
            type="submit"
            color="primary"
            >{{ mode == "edit" ? "Update" : "Create" }}</QBtn
         >
      </div>
   </QForm>
</template>
<script setup lang="ts">
import _ from "lodash"
import AllowedValuesField from "./AllowedValuesField.vue"
import { QForm, QInput, QBtn, QSelectOption, QSelect } from "quasar"
import { ref, computed, Ref, VNodeRef, watch, PropType } from "vue"
import {
   ValueChoice,
   BaseVisualVariableConfig,
   SetAttributeConfig,
   VisualVariableConfig,
} from "../types/variable"
import { useRoute } from "vue-router"
import { EditableVariable } from "../types/variable"
import { useProjectStore } from "@stores/index"
const props = defineProps({
   mode: {
      type: String as PropType<"create" | "edit">,
      required: true,
   },
   variable: {
      type: Object as PropType<EditableVariable>,
      required: true,
   },
})

const route = useRoute()
const projectStore = useProjectStore()

const actionOptions: Ref<Array<QSelectOption>> = ref([
   { label: "Set Text", value: "setText" },
   { label: "Set Style", value: "setStyle" },
   { label: "Set Inner HTML", value: "setInnerHTML" },
   { label: "Set Outer HTML", value: "setOuterHTML" },
   { label: "Set Attribute", value: "setAttribute" },
   { label: "Set Href", value: "setHref" },
   { label: "Set Src", value: "setSrc" },
   { label: "Set Font Size", value: "setFontSize" },
   { label: "Set Font Color", value: "setFontColor" },
   { label: "Set Background Color", value: "setBackgroundColor" },
   { label: "Set Visibility", value: "setVisibility" },
   { label: "Add Global CSS", value: "addGlobalCSS" },
])
const variable = props.variable
const baseAction = variable.config?.action
   ? actionOptions.value.find((v) => v.value === variable.config?.action)
   : actionOptions.value[0]
const actionOption: Ref<QSelectOption> = ref(baseAction as QSelectOption)
const actionValue = computed(() => actionOption.value?.value)
const key: Ref<string> = ref(variable.key)

const humanReadableName = ref(variable.humanReadableName)
const selector = ref(variable.config?.selector)

const baseAllowedValues = variable.constraints.enumerables.map((v) => {
   return { value: v, default: v === variable.defaultValue }
})
if (variable.defaultValue) {
   baseAllowedValues.push({ value: variable.defaultValue, default: true })
}

const attribute: Ref<string | null> = ref(null)
if (variable.config?.action === "setAttribute") {
   attribute.value = (variable.config as SetAttributeConfig).attribute
}
const form: VNodeRef = ref(null)
const allowedValuesField: VNodeRef = ref(null)
const variableId = ref(Number(route.params.variableId))

const resetValidation = () => {
   form.value.resetValidation()
   allowedValuesField.value.resetValidation()
}
// Expose resetValidation to parent component
defineExpose({
   resetValidation,
})

const $emit = defineEmits(["variable-submission", "delete-variable"])

watch(actionValue, () => {
   resetValidation()
   allowedValues.value = [] as Array<ValueChoice>
   allowedValuesField.value.updateAllowedValues(allowedValues.value)
})

const allowedValueType = computed(() => {
   switch (actionValue.value) {
      case "setText":
         return "string"
      case "setInnerHTML":
         return "html"
      case "setOuterHTML":
         return "html"
      case "setStyle":
         return "style"
      case "setAttribute":
         return "attribute"
      case "setHref":
         return "url"
      case "setSrc":
         return "url"
      case "addGlobalCSS":
         return "css"
   }
   return "unknown"
})

const showCSSSelectorInput = computed(() => {
   return actionValue.value !== "addGlobalCSS"
})

const defaultValue = computed(() => {
   return allowedValues.value.find((v) => v.default)?.value
})

const updateAllowedValues = (newAllowedValues: Array<ValueChoice>) => {
   allowedValues.value = newAllowedValues
}

const allowedValues: Ref<Array<ValueChoice>> = ref(baseAllowedValues)

const handleSubmit = async () => {
   form.value.validate()
   const allowedValuesValid = allowedValuesField.value.validate()
   if (!allowedValuesValid) {
      return
   }

   const baseVariableConfig = {
      selector: selector.value,
      action: actionValue.value,
   }
   const variableConfig: Ref<VisualVariableConfig> = ref(
      baseVariableConfig as BaseVisualVariableConfig,
   )
   if (actionValue.value === "setAttribute") {
      variableConfig.value = {
         ...baseVariableConfig,
         attribute: attribute.value,
      } as SetAttributeConfig
   }
   if (actionValue.value === "addGlobalCSS") {
      variableConfig.value = {
         action: actionValue.value,
      }
   }
   // filter out any items with default: true
   const allowedValuesArray = allowedValues.value
      .filter((v) => !v.default)
      .map((v) => v.value)
   if (props.mode === "edit") {
      const variablePayload: EditableVariable = {
         key: key.value,
         humanReadableName: humanReadableName.value,
         type: "visual",
         version: "0.2",
         projectId: projectStore.projectId!,
         config: variableConfig.value,
         constraints: {
            enumerables: allowedValuesArray,
         },
         constraintsVersion: "0.1",
         defaultValue: String(defaultValue.value),
      }
      $emit("variable-submission", variablePayload)
   } else {
      const variablePayload: EditableVariable = {
         key: key.value,
         humanReadableName: humanReadableName.value,
         type: "visual",
         version: "0.2",
         projectId: projectStore.projectId!,
         config: variableConfig.value,
         constraints: {
            enumerables: allowedValuesArray,
         },
         constraintsVersion: "0.1",
         defaultValue: String(defaultValue.value),
      }
      $emit("variable-submission", variablePayload)
   }
}

const handleDeleteVariable = (variableId: number) => {
   $emit("delete-variable", variableId)
}

const validateAttribute = (val: string): string | boolean => {
   const errorMessage =
      "Must be in kabob-case like 'data-load', 'href, or 'my-attribute'"
   if (!val) {
      return true
   }
   if (val && !val.match(/^[a-z0-9-]+$/)) {
      return errorMessage
   }

   return true
}

const validateSelector = (val: string): string | boolean => {
   const errorMessage =
      "Must be a valid CSS selector like #id', .class, tag, or #id .class > p"
   if (!val) {
      return errorMessage
   }
   // TODO: FIX ME
   // Test if valid CSS selector like "#id", ".class", "tag", or "#id .class > p"
   // if (!val.match(/^[#.][a-zA-Z0-9_#.\-[\]:"'= >]+$/)) {
   //    return errorMessage
   // }
   return true
}
const showDisabledSubmit = computed(() => {
   return variable.humanReadableName === humanReadableName.value &&
      variable.key === key.value &&
      variable.config?.selector === selector.value &&
      variable.config?.action === actionValue.value &&
      _.isEqual(
         variable.constraints.enumerables,
         allowedValues.value.map((v) => v.value),
      ) &&
      variable.defaultValue ===
         allowedValues.value.find((v) => v.default)?.value
      ? true
      : false
})
</script>
<style lang="scss">
#created-by-chip {
   margin-left: 0;
}
#variable-edit-form {
   border: 1px dotted darkgrey;
   padding: 1em;
   margin-bottom: 1em;
   width: 100%;
   max-width: 600px;
}
.q-field {
   margin-bottom: 10px;
}
#submit-row {
   margin-top: 25px;
   &.edit {
      justify-content: space-between;
   }
   &.create {
      justify-content: flex-end;
   }
}
#action-selector {
   width: 250px;
}
</style>
