import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import { requireEzbotEmployee } from "../guards/control"

// Import any employee-specific views here
// For example:
// import EmployeeDashboard from "@views/employee/EmployeeDashboard.vue"

// This is a placeholder component - you'll need to create this file
import EmployeeDashboard from "@views/employee/EmployeeDashboard.vue"

export default [
   {
      name: "EmployeeDashboard",
      path: "/employee/dashboard",
      component: EmployeeDashboard,
      meta: {
         layout: "ProjectLayout", // Using the same layout as projects
      },
      beforeEnter: async (
         to: RouteLocationNormalized,
         from: RouteLocationNormalized,
         next: NavigationGuardNext,
      ) => {
         return requireEzbotEmployee(to, from, next)
      },
   },
   // Add more employee routes here as needed
]
