<template>
   <div id="main-container" class="bg-white q-pa-md">
      <DesktopInstructions v-if="$q.screen.gt.sm" />
      <MobileInstructions v-else />
   </div>
</template>
<script setup lang="ts">
import { useQuasar } from "quasar"
import { MobileInstructions, DesktopInstructions } from "../components/index"
const $q = useQuasar()
</script>
