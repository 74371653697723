<template>
   <div>
      <div class="chart-container">
         <Line
            id="chart"
            ref="line"
            :options="chartOptions"
            :data="props.chartData"
            :height="props.height"
         />
      </div>
   </div>
</template>

<script setup lang="ts">
import {
   ref,
   Ref,
   onMounted,
   onUnmounted,
   VNodeRef,
   PropType,
   computed,
} from "vue"
import { Line } from "vue-chartjs"
import { enUS } from "date-fns/locale"

import {
   Chart as ChartJS,
   Tooltip,
   LineElement,
   ChartData,
   PointElement,
   TimeScale,
   LinearScale,
   ChartOptions,
   Legend,
   Title,
} from "chart.js"

ChartJS.register(
   Tooltip,
   LineElement,
   TimeScale,
   LinearScale,
   PointElement,
   Legend,
   Title,
)

import "chartjs-adapter-date-fns"

const props = defineProps({
   height: {
      type: String,
      required: true,
   },
   showLegend: {
      type: Boolean,
      required: true,
   },
   chartData: {
      type: Object as PropType<ChartData<"line">>,
      required: true,
   },
})

const line: VNodeRef = ref(null)
const tooltipEnabled = ref(true)

const chartOptions: Ref<ChartOptions<"line">> = computed(() => ({
   responsive: true,
   interaction: {
      mode: props.showLegend ? "index" : "nearest",
      intersect: false,
   },
   elements: {
      line: {
         tension: 0.4,
      },
   },
   scales: {
      y: {
         beginAtZero: false,
         position: "right",
         grid: {
            display: true,
            lineWidth: (context) => {
               const length = context.scale.ticks.length
               if (context.index === 0 || context.index === length - 1) {
                  return 0
               }
               return 1
            },
         },
         ticks: {
            // Include a percentage sign in the ticks
            callback: function (tickValue: string | number) {
               return Number(tickValue).toFixed(2) + "%"
            },
         },
      },
      x: {
         display: true,
         grid: {
            display: false,
         },
         type: "time",
         time: {
            unit: "day",
            // round: "day",
            // tooltipFormat: "MMM d HH:00",
         },
         adapters: {
            date: {
               locale: enUS,
            },
         },
         ticks: {
            source: "auto",
            autoSkip: true,
            autoSkipPadding: 75,
            maxRotation: 0,
         },
      },
   },
   plugins: {
      title: {
         display: true,
         text: "Conversion Rate Improvement",
         font: {
            size: 18,
         },
      },
      legend: {
         display: props.showLegend,
         position: "top",
         onHover: function (event, legendItem, legend) {
            ;(event.native?.target as HTMLElement)?.classList.add(
               "cursor-pointer",
            )
            tooltipEnabled.value = false
            legend.chart.data.datasets.forEach((e, i) => {
               if (i === legendItem.datasetIndex) {
                  e.hidden = false
               } else {
                  e.hidden = true
               }
            })
            legend.chart.update()
         },

         onClick: function (event, legendItem, legend) {
            legend.chart.data.datasets.forEach((e, i) => {
               if (i === legendItem.datasetIndex) {
                  e.hidden = false
               } else {
                  e.hidden = true
               }
            })
            legend.chart.update()
         },
         onLeave: function (event, legendItem, legend) {
            tooltipEnabled.value = true
            ;(event.native?.target as HTMLElement)?.classList.add("default")
            legend.chart.data.datasets.forEach((e) => {
               e.hidden = false
            })
            legend.chart.update()
         },
      },
      tooltip: {
         enabled: tooltipEnabled.value,
         padding: 10,
         caretPadding: 10,
         itemSort: (a, b) => b.parsed.y - a.parsed.y,
         filter: (context) => {
            return context.datasetIndex !== 0
         },
         callbacks: {
            title: (context) => {
               const date = new Date(context[0].parsed.x)
               const options = <Intl.DateTimeFormatOptions>{
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  timeZoneName: "short",
               }
               return date.toLocaleString(undefined, options)
            },
            label: function (context) {
               var label = context.dataset.label || ""

               if (label) {
                  label += ": "
               }
               if (context.parsed.y !== null) {
                  label += context.parsed.y.toFixed(2) + "%"
               }
               return label
            },
            beforeBody: () => "Conversion Rates:",
         },
      },
   },
}))

const handleResize = () => {
   if (!line.value) return
   line.value.chart.resize()
}

onMounted(() => {
   window.addEventListener("resize", handleResize)
})

onUnmounted(() => {
   window.removeEventListener("resize", handleResize)
})
</script>
<style lang="scss">
.chart-container {
   position: relative;
   height: 100%;
   width: 100%;
}
#chart {
   position: relative;
   height: 100%;
   width: 100%;
}
</style>
