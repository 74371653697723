<template>
   <span>
      {{ formatPercentTwoDecimals(val) }}
   </span>
   <span>
      <QIcon
         v-if="props.val > props.comparisonVal"
         name="mdi-arrow-up"
         color="green"
         ><QTooltip>{{ props.tooltipText }} </QTooltip></QIcon
      >
      <QIcon v-else name="mdi-arrow-down" color="red"
         ><QTooltip>{{ props.tooltipText }} </QTooltip></QIcon
      >
   </span>
</template>
<script setup lang="ts">
import { PropType } from "vue"
import { formatPercentTwoDecimals } from "./formatters"

const props = defineProps({
   val: {
      type: Number as PropType<number>,
      required: true,
   },
   comparisonVal: {
      type: Number as PropType<number>,
      required: true,
   },
   tooltipText: {
      type: String,
      required: true,
   },
})
</script>
