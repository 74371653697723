import axios, {
   AxiosInstance,
   AxiosResponse,
   InternalAxiosRequestConfig,
} from "axios"
import { clerk, getOrgId } from "./clerk"

import type {
   DBVariable,
   Project,
   ProjectSettings,
   UpdateProjectSettingsPayload,
   WaitlistUser,
} from "../types"
import type { StripePrice } from "../types/stripe.d"
import type { EditableVariable } from "../types/variable"
import { logInfo } from "@utils/logging"
import { useProjectStore } from "@stores/projectStore"
import { addBreadcrumb } from "./sentry"

const adminBackendClient: AxiosInstance = axios.create({
   baseURL: <string>import.meta.env.VITE_API_SERVER_URL,
   timeout: 5000,
})

adminBackendClient.interceptors.request.use(
   async (config: InternalAxiosRequestConfig) => {
      await clerk.load()
      const token = await clerk.session?.getToken()
      if (!token) {
         logInfo("No Clerk token found, redirecting to sign-in")
         window.location.href = "/sign-in"
      }
      addBreadcrumb({
         category: "API",
         message: `Calling Admin Backend API: ${config.url}`,
         level: "info",
      })
      if (config.headers) {
         config.headers.Authorization = `Bearer ${token}`
      }
      return config
   },
   (error) => {
      // TODO: Not sure if I should use a response interceptor
      console.log("Error in request interceptor")
      const s = error.response.status
      if (s === 401 || s === 403) {
         addBreadcrumb({
            category: "Auth",
            message: `Unauthorized: ${error.response.config.url}`,
            level: "error",
         })
         console.log("Unauthorized")
      }
   },
)

export default adminBackendClient

const getVariablesByProjectId = async (
   projectId: number,
): Promise<AxiosResponse<DBVariable[]>> => {
   const response = await adminBackendClient.get(
      `/v2/projects/${projectId}/variables`,
   )
   return response
}

const getVariable = async (id: number): Promise<AxiosResponse<DBVariable>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.get(
      `/v2/projects/${projectId}/variables/${id}`,
   )
}

const updateVariable = async (
   id: number,
   payload: EditableVariable,
): Promise<AxiosResponse<DBVariable>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.patch(
      `/v2/projects/${projectId}/variables/${id}`,
      payload,
   )
}

const deleteVariable = async (id: number): Promise<AxiosResponse<boolean>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.delete(
      `/v2/projects/${projectId}/variables/${id}`,
   )
}

const createVariable = async (
   payload: EditableVariable,
): Promise<AxiosResponse<DBVariable>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.post(
      `/v2/projects/${projectId}/variables`,
      payload,
   )
}

const getProjectSettings = async (
   projectId: number,
): Promise<AxiosResponse<ProjectSettings>> => {
   return await adminBackendClient.get(`/v2/projects/${projectId}/settings`)
}

const updateActiveProjectSettings = async (
   payload: UpdateProjectSettingsPayload,
): Promise<AxiosResponse<ProjectSettings>> => {
   const { projectId } = useProjectStore()
   return await adminBackendClient.patch(
      `/v2/projects/${projectId}/settings`,
      payload,
   )
}

const getProjectsByActiveOrg = async (): Promise<AxiosResponse<Project[]>> => {
   return await adminBackendClient.get(`/v2/projects`)
}

const createProject = async (
   humanReadableName: string,
   orgId: string,
): Promise<AxiosResponse<Project>> => {
   return await adminBackendClient.post(`/v2/projects`, {
      humanReadableName: humanReadableName,
      organizationId: orgId,
   })
}

const deleteProject = async (
   projectId: number,
): Promise<AxiosResponse<boolean>> => {
   return await adminBackendClient.delete(`/v2/projects/${projectId}`)
}

const updateProject = async (
   projectId: number,
   orgId: string,
   humanReadableName: string,
): Promise<AxiosResponse<Project>> => {
   return await adminBackendClient.patch(`/v2/projects/${projectId}`, {
      humanReadableName: humanReadableName,
      organizationId: orgId,
   })
}

const updateCurrentProject = async (
   humanReadableName: string,
): Promise<AxiosResponse<Project>> => {
   const { projectId } = useProjectStore()
   const orgId = await getOrgId()
   if (!projectId) {
      throw new Error("No project selected")
   }
   if (!orgId) {
      throw new Error("No orgId found")
   }
   return await updateProject(projectId, orgId, humanReadableName)
}

interface WaitlistQueryParams {
   limit?: number
   offset?: number
   query?: string
   status?: "pending" | "invited" | "completed" | "rejected"
   order_by?: string
}

const getWaitlistUsers = async (
   params?: WaitlistQueryParams,
): Promise<
   AxiosResponse<{
      data: WaitlistUser[]
      total_count: number
   }>
> => {
   // Mock data in development mode
   if (import.meta.env.VITE_MOCK_EMPLOYEE === "true") {
      console.log("Development mode: Using mock waitlist data")

      // Generate mock waitlist users
      const mockUsers: WaitlistUser[] = [
         {
            object: "waitlist_user",
            id: "user_1",
            email_address: "john.doe@example.com",
            status: "pending",
            is_locked: false,
            created_at: Math.floor(
               (Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000,
            ),
            updated_at: Math.floor(
               (Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000,
            ),
            invitation: {
               object: "invitation",
               id: "inv_1",
               email_address: "john.doe@example.com",
               public_metadata: {},
               revoked: false,
               status: "pending",
               url: "https://example.com/invite/1",
               expires_at: null,
               created_at: Math.floor(
                  (Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000,
               ),
               updated_at: Math.floor(
                  (Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000,
               ),
            },
         },
         {
            object: "waitlist_user",
            id: "user_2",
            email_address: "jane.smith@example.com",
            status: "invited",
            is_locked: false,
            created_at: Math.floor(
               (Date.now() - 5 * 24 * 60 * 60 * 1000) / 1000,
            ),
            updated_at: Math.floor(
               (Date.now() - 5 * 24 * 60 * 60 * 1000) / 1000,
            ),
            invitation: {
               object: "invitation",
               id: "inv_2",
               email_address: "jane.smith@example.com",
               public_metadata: {},
               revoked: false,
               status: "invited",
               url: "https://example.com/invite/2",
               expires_at: null,
               created_at: Math.floor(
                  (Date.now() - 5 * 24 * 60 * 60 * 1000) / 1000,
               ),
               updated_at: Math.floor(
                  (Date.now() - 5 * 24 * 60 * 60 * 1000) / 1000,
               ),
            },
         },
         {
            object: "waitlist_user",
            id: "user_3",
            email_address: "mike.johnson@example.com",
            status: "completed",
            is_locked: false,
            created_at: Math.floor(
               (Date.now() - 3 * 24 * 60 * 60 * 1000) / 1000,
            ),
            updated_at: Math.floor(
               (Date.now() - 3 * 24 * 60 * 60 * 1000) / 1000,
            ),
            invitation: {
               object: "invitation",
               id: "inv_3",
               email_address: "mike.johnson@example.com",
               public_metadata: {},
               revoked: false,
               status: "completed",
               url: "https://example.com/invite/3",
               expires_at: null,
               created_at: Math.floor(
                  (Date.now() - 3 * 24 * 60 * 60 * 1000) / 1000,
               ),
               updated_at: Math.floor(
                  (Date.now() - 3 * 24 * 60 * 60 * 1000) / 1000,
               ),
            },
         },
         {
            object: "waitlist_user",
            id: "user_4",
            email_address: "sarah.williams@example.com",
            status: "pending",
            is_locked: false,
            created_at: Math.floor(
               (Date.now() - 1 * 24 * 60 * 60 * 1000) / 1000,
            ),
            updated_at: Math.floor(
               (Date.now() - 1 * 24 * 60 * 60 * 1000) / 1000,
            ),
            invitation: {
               object: "invitation",
               id: "inv_4",
               email_address: "sarah.williams@example.com",
               public_metadata: {},
               revoked: false,
               status: "pending",
               url: "https://example.com/invite/4",
               expires_at: null,
               created_at: Math.floor(
                  (Date.now() - 1 * 24 * 60 * 60 * 1000) / 1000,
               ),
               updated_at: Math.floor(
                  (Date.now() - 1 * 24 * 60 * 60 * 1000) / 1000,
               ),
            },
         },
         {
            object: "waitlist_user",
            id: "user_5",
            email_address: "david.brown@example.com",
            status: "rejected",
            is_locked: true,
            created_at: Math.floor(
               (Date.now() - 10 * 24 * 60 * 60 * 1000) / 1000,
            ),
            updated_at: Math.floor(
               (Date.now() - 10 * 24 * 60 * 60 * 1000) / 1000,
            ),
            invitation: {
               object: "invitation",
               id: "inv_5",
               email_address: "david.brown@example.com",
               public_metadata: {},
               revoked: true,
               status: "rejected",
               url: "https://example.com/invite/5",
               expires_at: null,
               created_at: Math.floor(
                  (Date.now() - 10 * 24 * 60 * 60 * 1000) / 1000,
               ),
               updated_at: Math.floor(
                  (Date.now() - 10 * 24 * 60 * 60 * 1000) / 1000,
               ),
            },
         },
      ]

      // Apply filtering based on params
      let filteredUsers = [...mockUsers]

      // Filter by status if provided
      if (params?.status) {
         filteredUsers = filteredUsers.filter(
            (user) => user.status === params.status,
         )
      }

      // Filter by search query if provided
      if (params?.query) {
         const query = params.query.toLowerCase()
         filteredUsers = filteredUsers.filter(
            (user) =>
               user.email_address.toLowerCase().includes(query) ||
               user.status.toLowerCase().includes(query),
         )
      }

      // Get total count before pagination
      const totalCount = filteredUsers.length

      // Apply pagination
      const offset = params?.offset || 0
      const limit = params?.limit || 10
      filteredUsers = filteredUsers.slice(offset, offset + limit)

      // Return mock response
      return Promise.resolve({
         data: {
            data: filteredUsers,
            total_count: totalCount,
         },
         status: 200,
         statusText: "OK",
         headers: {},
         config: {} as InternalAxiosRequestConfig,
      } as AxiosResponse<{
         data: WaitlistUser[]
         total_count: number
      }>)
   }

   // Real API call in production
   return await adminBackendClient.get(`/v2/admin/clerk/waitlist`, { params })
}

interface StripeQueryParams {
   active?: boolean
   currency?: string
   product?: string
   type?: "one_time" | "recurring"
   limit?: number
}

const getStripePrices = async (
   params?: StripeQueryParams,
): Promise<
   AxiosResponse<{
      data: StripePrice[]
      total_count: number
   }>
> => {
   // Mock data in development mode
   if (import.meta.env.VITE_MOCK_EMPLOYEE === "true") {
      console.log("Development mode: Using mock price data")

      // Generate mock price data
      const mockPrices: StripePrice[] = [
         {
            id: "price_1NxYZ2ABC123DEF456GHI7J",
            object: "price",
            active: true,
            billing_scheme: "per_unit",
            created: Date.now() - 30 * 24 * 60 * 60 * 1000,
            currency: "usd",
            livemode: false,
            lookup_key: null,
            metadata: {},
            nickname: "Basic Monthly",
            product: "prod_ABC123DEF456",
            type: "recurring",
            recurring: {
               interval: "month",
               interval_count: 1,
               usage_type: "licensed",
               aggregate_usage: null,
               trial_period_days: null,
            },
            tax_behavior: "unspecified",
            unit_amount: 1999,
            unit_amount_decimal: "1999",
         },
         {
            id: "price_2OxYZ2ABC123DEF456GHI7J",
            object: "price",
            active: true,
            billing_scheme: "per_unit",
            created: Date.now() - 30 * 24 * 60 * 60 * 1000,
            currency: "usd",
            livemode: false,
            lookup_key: null,
            metadata: {},
            nickname: "Basic Annual",
            product: "prod_ABC123DEF456",
            type: "recurring",
            recurring: {
               interval: "year",
               interval_count: 1,
               usage_type: "licensed",
               aggregate_usage: null,
               trial_period_days: null,
            },
            tax_behavior: "unspecified",
            unit_amount: 19990,
            unit_amount_decimal: "19990",
         },
         {
            id: "price_3PxYZ2ABC123DEF456GHI7J",
            object: "price",
            active: true,
            billing_scheme: "per_unit",
            created: Date.now() - 15 * 24 * 60 * 60 * 1000,
            currency: "usd",
            livemode: false,
            lookup_key: null,
            metadata: {},
            nickname: "Premium Monthly",
            product: "prod_DEF456GHI789",
            type: "recurring",
            recurring: {
               interval: "month",
               interval_count: 1,
               usage_type: "licensed",
               aggregate_usage: null,
               trial_period_days: null,
            },
            tax_behavior: "unspecified",
            unit_amount: 4999,
            unit_amount_decimal: "4999",
         },
         {
            id: "price_4QxYZ2ABC123DEF456GHI7J",
            object: "price",
            active: true,
            billing_scheme: "per_unit",
            created: Date.now() - 15 * 24 * 60 * 60 * 1000,
            currency: "usd",
            livemode: false,
            lookup_key: null,
            metadata: {},
            nickname: "Premium Annual",
            product: "prod_DEF456GHI789",
            type: "recurring",
            recurring: {
               interval: "year",
               interval_count: 1,
               usage_type: "licensed",
               aggregate_usage: null,
               trial_period_days: null,
            },
            tax_behavior: "unspecified",
            unit_amount: 49990,
            unit_amount_decimal: "49990",
         },
         {
            id: "price_5RxYZ2ABC123DEF456GHI7J",
            object: "price",
            active: false,
            billing_scheme: "per_unit",
            created: Date.now() - 90 * 24 * 60 * 60 * 1000,
            currency: "usd",
            livemode: false,
            lookup_key: null,
            metadata: {},
            nickname: "Legacy Monthly",
            product: "prod_GHI789JKL012",
            type: "recurring",
            recurring: {
               interval: "month",
               interval_count: 1,
               usage_type: "licensed",
               aggregate_usage: null,
               trial_period_days: null,
            },
            tax_behavior: "unspecified",
            unit_amount: 2999,
            unit_amount_decimal: "2999",
         },
      ]

      // Apply filtering based on params
      let filteredPrices = [...mockPrices]

      // Filter by active status
      if (params?.active !== undefined) {
         filteredPrices = filteredPrices.filter(
            (price) => price.active === params.active,
         )
      }

      // Filter by currency
      if (params?.currency) {
         filteredPrices = filteredPrices.filter(
            (price) => price.currency === params.currency,
         )
      }

      // Filter by product
      if (params?.product) {
         filteredPrices = filteredPrices.filter(
            (price) => price.product === params.product,
         )
      }

      // Filter by type
      if (params?.type) {
         filteredPrices = filteredPrices.filter(
            (price) => price.type === params.type,
         )
      }

      // Get total count
      const totalCount = filteredPrices.length

      // Apply pagination if limit is provided
      if (params?.limit) {
         filteredPrices = filteredPrices.slice(0, params.limit)
      }

      // Return mock response
      return Promise.resolve({
         data: {
            data: filteredPrices,
            total_count: totalCount,
         },
         status: 200,
         statusText: "OK",
         headers: {},
         config: {} as InternalAxiosRequestConfig,
      } as AxiosResponse<{
         data: StripePrice[]
         total_count: number
      }>)
   }

   // Real API call in production
   return await adminBackendClient.get(`/v2/admin/stripe/prices`, { params })
}

export {
   adminBackendClient,
   getVariable,
   getVariablesByProjectId,
   updateVariable,
   deleteVariable,
   createVariable,
   getProjectSettings,
   updateActiveProjectSettings,
   getProjectsByActiveOrg,
   createProject,
   deleteProject,
   updateProject,
   updateCurrentProject,
   getWaitlistUsers,
   getStripePrices,
   StripeQueryParams,
   StripePrice,
}
