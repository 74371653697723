<template>
   <QChip
      :label="$props.title + ': '"
      :size="$props.size"
      :icon="$props.icon"
      :color="chipColor"
      class="kpi-message-chip q-pa-md"
      outline
   >
      <span v-if="$props.number" class="text-bold q-ml-md">
         <CountUp :end-val="$props.number" :options="baseCountUpOptions">
         </CountUp>
      </span>
   </QChip>
</template>
<script setup lang="ts">
import { computed, PropType } from "vue"
import CountUp from "vue-countup-v3"
const props = defineProps({
   title: {
      type: String,
      required: true,
   },
   icon: {
      type: String,
      required: true,
   },
   number: {
      type: Number as PropType<number | undefined>,
      required: false,
   },
   color: {
      type: String,
      required: false,
      default: "primary",
   },
   numberUnits: {
      type: String,
      required: false,
      default: "none",
   },
   size: {
      type: String,
      required: false,
      default: "md",
   },
})
const chipColor = computed(() => {
   if (props.color) {
      return props.color
   }

   if (props.number && props.number > 0) {
      return "positive"
   } else {
      return "primary"
   }
})
const baseCountUpOptions = {
   duration: 2,
   useEasing: true,
   useGrouping: true,
   separator: ",",
}
</script>
<style scoped lang="scss">
.text-h6 {
   padding: 0.25em;
}
.color-positive {
   color: $positive;
}
.color-negative {
   color: $negative;
}
.kpi-number {
   margin: 0.5em;
   font-weight: bold;
}
.kpi-subtitle {
   font-style: italic;
}
.kpi-percent-change {
   font-size: 0.6em;
   font-weight: bold;
   font-style: italic;
}
</style>
