<template>
   <QForm id="variable-edit-form" ref="form" @submit="handleSubmit">
      <q-input
         v-model="humanReadableName"
         filled
         label="Human Readable Name"
         lazy-rules
         :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />
      <q-input
         v-model="key"
         filled
         label="Variable Key"
         lazy-rules
         :rules="[validateKey]"
      />
      <AllowedValuesField
         ref="allowedValuesField"
         :allowed-values="allowedValues"
         :allowed-value-type="allowedValueType"
         @added-allowed-value="updateAllowedValues"
         @removed-allowed-value="updateAllowedValues"
         @set-default-value="updateAllowedValues"
      />
      <div
         id="submit-row"
         :class="{
            row: true,
            edit: mode == 'edit',
            create: mode == 'create',
         }"
      >
         <QBtn
            v-if="mode == 'edit'"
            color="negative"
            @click="handleDeleteVariable(variableId)"
            ><QIcon name="mdi-delete"
         /></QBtn>
         <QBtn
            id="submit"
            :disabled="showDisabledSubmit"
            type="submit"
            color="primary"
            >{{ mode == "edit" ? "Update" : "Create" }}</QBtn
         >
      </div>
   </QForm>
</template>
<script setup lang="ts">
import _ from "lodash"
import AllowedValuesField from "./AllowedValuesField.vue"
import { QForm, QInput, QBtn } from "quasar"
import { ref, VNodeRef, PropType, Ref, computed } from "vue"
import { ValueChoice } from "../types/variable"
import { useRoute } from "vue-router"
import { EditableVariable } from "../types/variable"
import { useProjectStore } from "@stores/index"

const form: VNodeRef = ref(null)
const allowedValuesField: VNodeRef = ref(null)
const route = useRoute()
const projectStore = useProjectStore()
const variableId = ref(Number(route.params.variableId))
const resetValidation = () => {
   form.value.resetValidation()
   allowedValuesField.value.resetValidation()
}
// Expose resetValidation to parent component
defineExpose({
   resetValidation,
})
const props = defineProps({
   mode: {
      type: String as PropType<"create" | "edit">,
      required: true,
   },
   variable: {
      type: Object as PropType<EditableVariable>,
      required: true,
   },
})

const variable = props.variable
const humanReadableName = ref(variable.humanReadableName)
const key = ref(variable.key)
const baseAllowedValues = variable.constraints.enumerables.map((v) => {
   return { value: v, default: v === variable.defaultValue }
})
if (variable.defaultValue) {
   baseAllowedValues.push({ value: variable.defaultValue, default: true })
}

const allowedValues: Ref<Array<ValueChoice>> = ref(baseAllowedValues)

const $emit = defineEmits(["variable-submission", "delete-variable"])

const updateAllowedValues = (newAllowedValues: Array<ValueChoice>) => {
   allowedValues.value = newAllowedValues
}

const defaultValue = computed(() => {
   return allowedValues.value.find((v) => v.default)?.value
})

const allowedValueType = "basic"

const handleSubmit = async () => {
   if (!projectStore.projectId) {
      throw new Error("No project ID found")
   }
   const allowedValuesArray = allowedValues.value
      .filter((v) => !v.default)
      .map((v) => v.value)
   if (props.mode === "edit") {
      const variablePayload: EditableVariable = {
         key: key.value,
         humanReadableName: humanReadableName.value,
         type: "basic",
         version: "0.1",
         projectId: projectStore.projectId,
         config: null,
         constraints: {
            enumerables: allowedValuesArray,
         },
         constraintsVersion: "0.1",
         defaultValue: String(defaultValue.value),
      }
      $emit("variable-submission", variablePayload)
   } else {
      const variablePayload: EditableVariable = {
         key: key.value,
         humanReadableName: humanReadableName.value,
         type: "basic",
         version: "0.1",
         projectId: projectStore.projectId,
         config: null,
         constraints: {
            enumerables: allowedValuesArray,
         },
         constraintsVersion: "0.1",
         defaultValue: String(defaultValue.value),
      }
      $emit("variable-submission", variablePayload)
   }
}

const handleDeleteVariable = (variableId: number) => {
   $emit("delete-variable", variableId)
}

const validateKey = (val: string): string | boolean => {
   if (!val) {
      return "A valid snake case key is required"
   }
   if (!val.match(/^[a-z0-9_]+$/)) {
      return "Must be a valid snake case string like 'my_variable_key'"
   }
   return true
}

const showDisabledSubmit = computed(() => {
   return variable.humanReadableName === humanReadableName.value &&
      variable.key === key.value &&
      _.isEqual(
         variable.constraints.enumerables,
         allowedValues.value.map((v) => v.value),
      ) &&
      variable.defaultValue ===
         allowedValues.value.find((v) => v.default)?.value
      ? true
      : false
})
</script>
<style lang="scss">
#created-by-chip {
   margin-left: 0;
}
#variable-edit-form {
   border: 1px dotted darkgrey;
   padding: 1em;
   margin-bottom: 1em;
   width: 100%;
   max-width: 600px;
}
.q-field {
   margin-bottom: 10px;
}
#submit-row {
   margin-top: 25px;
   &.edit {
      justify-content: space-between;
   }
   &.create {
      justify-content: flex-end;
   }
}
</style>
