<template>
   <QDialog v-model="show" persistent @hide="onHide">
      <div id="form-container">
         <QForm @submit="handleSubmit">
            <h1 class="text-h4">Create a Project</h1>
            <QInput
               v-model="humanReadableName"
               filled
               label="Human Readable Name"
               lazy-rules
               :rules="[
                  (val) => (val && val.length > 0) || 'Please type something',
               ]"
            />
            <div class="row justify-end">
               <QBtn id="back" color="secondary" @click="handleBack">Back</QBtn>
               <QBtn id="submit" type="submit" color="primary">Submit</QBtn>
            </div>
         </QForm>
      </div>
   </QDialog>
</template>
<script setup lang="ts">
import { QForm, QInput, QDialog } from "quasar"
import { ref } from "vue"
import { useProjectStore } from "@/stores"
import { useAuth } from "@clerk/vue"
import { useRouter } from "vue-router"
import { toast } from "vue3-toastify"
import { getOrg } from "@/services/clerk"

const projectStore = useProjectStore()
const humanReadableName = ref("")
const { orgId } = useAuth()
const router = useRouter()
const show = ref(true)

const handleSubmit = async () => {
   if (!orgId.value) {
      toast.error("No orgId found. Please contact support")
      throw new Error("No orgId found")
   }
   const project = await projectStore.createProject(
      humanReadableName.value,
      orgId.value,
   )
   if (!project) {
      toast.error("Failed to create project")
      throw new Error("Failed to create project")
   }
   projectStore.projectId = project.id
   show.value = false
   await projectStore.getActiveOrgProjects()
   await router.push({ name: "ProjectHome", params: { projectId: project.id } })

   toast.success("Project created.")
}

const onHide = () => {
   const previousProjectId = projectStore.projectId
   router.push({
      name: "ProjectHome",
      params: { projectId: previousProjectId },
   })
}
const org = await getOrg()
if (!org) {
   throw new Error("Org is undefined")
}
const { slug } = org
const handleBack = () => {
   router.push({ name: "OrgHome", params: { orgSlug: slug } })
}
</script>
<style lang="scss" scoped>
#onboarding-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100%;
   background-color: #f5f5f5;
}
#form-container {
   width: 100%;
   max-width: 400px;
   padding: 20px;
   background-color: white;
   border-radius: 5px;
   border: 1px solid #e0e0e0;
}
#back {
   margin-right: 10px;
}
</style>
