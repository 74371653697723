import {
   getNumberOfOrgs,
   hasActiveOrg,
   hasActiveSession,
   setFirstOrgAsActive,
} from "@/services/clerk"
import { addBreadcrumb } from "@/services/sentry"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"

const requireValidOrg = async (
   to: RouteLocationNormalized,
   from: RouteLocationNormalized,
   next: NavigationGuardNext,
) => {
   addBreadcrumb({
      category: "Route Guard",
      message: `Validating org ${to.fullPath}`,
      level: "info",
   })
   const loggedIn = await hasActiveSession()
   if (!loggedIn) {
      return next({ name: "SignIn" })
   }
   const numOrgs = await getNumberOfOrgs()
   if (numOrgs === 0) {
      return next({ name: "OnboardingCreateOrg" })
   }

   const activeOrg = await hasActiveOrg()

   if (!activeOrg) {
      if (numOrgs >= 1) {
         await setFirstOrgAsActive()
      } else if (to.name == "SelectOrg") {
         return next()
      } else {
         return next({ name: "SelectOrg" })
      }
   }

   return next()
}

export { requireValidOrg }
