<template>
   <div id="home-main-container" class="bg-white q-pa-md">
      <!-- Header Section -->
      <PageHeader
         v-model="selectedUtmSource"
         title="Variable Explorer"
         :start-date="startDate"
         :end-date="endDate"
         @update:dates="handleDateChange"
      />
      <InstallInstructions2 v-if="shouldShowInstructions" />
      <div v-else id="main-container">
         <!-- Installation Instructions or Stacked Graphs -->
         <VariableSnapshotCard
            :variable-snapshot="overviewStore.variableSnapshot"
            :hour-of-day="overviewStore.hourOfDay"
            @update:variables="handleVariableUpdate"
         />
      </div>
      <InstallInstructions v-if="shouldShowInstructions" />
   </div>
</template>
<script setup lang="ts">
// Imports
import { useQuasar } from "quasar"
import { useProjectStore } from "../stores"
import { useOverviewStore } from "@stores/overviewStore"
import { onMounted, watch, ref, computed } from "vue"
import InstallInstructions from "@components/installation-instructions/InstallInstructions.vue"
import VariableSnapshotCard from "@components/variable-snapshot/VariableSnapshotCard.vue"
import InstallInstructions2 from "@components/installation-instructions/InstallInstructions2.vue"
import PageHeader from "@/components/common/PageHeader.vue"

// Utilities
const $q = useQuasar()

// Stores
const projectStore = useProjectStore()
const overviewStore = useOverviewStore()

// State
const startDate = ref(
   overviewStore.start_date ??
      (() => {
         const date = new Date()
         date.setDate(date.getDate() - 30)
         return Math.floor(date.getTime())
      })(),
)

const endDate = ref(
   overviewStore.end_date ??
      (() => {
         const date = new Date()
         date.setDate(date.getDate() + 1)
         return Math.floor(date.getTime())
      })(),
)

const selectedUtmSource = ref<string | null>(null)
const selectedVariableId = ref<number | null>(null)

// Computed
const shouldShowInstructions = computed(() => {
   const hasData = !!overviewStore.variableSnapshot || !!overviewStore.hourOfDay
   return !overviewStore.loading && !hasData
})

// Handlers
const handleDateChange = async (newStartDate: number, newEndDate: number) => {
   startDate.value = newStartDate
   endDate.value = newEndDate
   await handleGetVariableExplorer()
}

const handleVariableUpdate = async (variableIds: number[]) => {
   selectedVariableId.value = variableIds[0]
   await handleGetVariableExplorer()
}

const handleGetVariableExplorer = async () => {
   if (!selectedVariableId.value) return

   await overviewStore.fetchVariableExplorer(
      startDate.value,
      endDate.value,
      selectedVariableId.value,
      selectedUtmSource.value,
   )
}

// Lifecycle Hooks
onMounted(async () => {
   // Fetch variables for CombinationDetailsDialog
   await projectStore.getActiveOrgProjects()
   await projectStore.getVariablesByProjectId()

   if (selectedVariableId.value == null && projectStore.variables.length > 0) {
      selectedVariableId.value =
         projectStore.variables[projectStore.variables.length - 1].id
   }

   if (selectedVariableId.value) {
      await handleGetVariableExplorer()
   }
})

// Watch for project changes to refresh data
watch(
   () => projectStore.projectId,
   async (newProjectId) => {
      if (newProjectId) {
         overviewStore.clearState()
         selectedVariableId.value = null
      }
   },
)

// Watch for UTM source changes
watch(selectedUtmSource, async () => {
   await handleGetVariableExplorer()
})

// Subscriptions
overviewStore.$subscribe((mutation, state) => {
   // Only show loading if we're loading AND don't have data yet
   if (state.loading && !state.variableSnapshot && !state.hourOfDay) {
      $q.loading.show()
   } else {
      $q.loading.hide()
   }
})
</script>
<style lang="scss" scoped>
#main-container {
   width: 100%;
}
#kpi-container {
   display: flex;
   align-items: center;
   gap: 2em;
   width: 100%;
   padding-top: 1em;
   padding-bottom: 1em;
}
.kpi-card {
   width: 100%;
}
#graphs-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 2em;
}
.graph-card {
   width: 100%;
}

#chips-container {
   display: flex;
   gap: 1em;
}
#conversion-rate-filter-container {
   display: flex;
   gap: 1em;
   // border: 1px solid rgb(160, 160, 160);
   // border-radius: 5px;
   padding: 1em 2em;
}
@media (max-width: 1400px) {
   #dashboard-title {
      margin-bottom: 0.5em;
   }
   .graph-card {
      width: 100%;
   }
   #kpi-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
   #graphs-container {
      flex-direction: column;
   }
   #dropdown-container {
      margin-bottom: 1em;
   }
   #chips-container {
      width: 100%;
   }
}
#home-main-container {
   min-height: 100vh;
}
#intro-paragraph {
   max-width: 800px;
}
</style>
