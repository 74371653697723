import * as Sentry from "@sentry/vue"
import { useProjectStore } from "@stores/projectStore"
import { Primitive } from "@sentry/types"
import { App } from "vue"
import { getOrg, getUser, hasActiveStripeSubscription } from "./clerk"
import { Router } from "vue-router"
import { useOverviewStore } from "@stores/overviewStore"

const initSentry = (app: App<Element>, router: Router): void => {
   const sentryEnabled = import.meta.env.VITE_SENTRY_ENABLED === "true"
   const env = import.meta.env.VITE_ENV
   if (!env) {
      console.error("VITE_ENV is not set")
      return
   }
   if (sentryEnabled) {
      const sentryDSN = import.meta.env.VITE_SENTRY_DSN
      if (!sentryDSN) {
         console.error("VITE_SENTRY_DSN is not set")
         return
      }
      const sentryBrowserDomain = import.meta.env.VITE_SENTRY_BROWSER_DOMAIN
      if (!sentryBrowserDomain) {
         console.error("VITE_SENTRY_BROWSER_DOMAIN is not set")
         return
      }
      const sentryApiDomain = import.meta.env.VITE_SENTRY_API_DOMAIN
      if (!sentryApiDomain) {
         console.error("VITE_SENTRY_API_DOMAIN is not set")
      }
      const sentryReplaysSessionSampleRate = import.meta.env
         .VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
      if (!sentryReplaysSessionSampleRate) {
         console.error("VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE is not set")
         return
      }
      const sentryReplaysSessionSampleRateFloat = parseFloat(
         String(sentryReplaysSessionSampleRate),
      )
      if (!sentryReplaysSessionSampleRateFloat) {
         console.error(
            "VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE is not set correctly",
         )
         return
      }
      const sentryReplaysOnErrorSampleRate = import.meta.env
         .VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
      if (!sentryReplaysSessionSampleRateFloat) {
         console.error("VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE is not set")
         return
      }
      const sentryReplaysOnErrorSampleRateFloat = parseFloat(
         String(sentryReplaysOnErrorSampleRate),
      )
      if (!sentryReplaysOnErrorSampleRateFloat) {
         console.error(
            "VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE is not set correctly",
         )
         return
      }
      const commitSHA = import.meta.env.VITE_COMMIT_SHA
      let commitSHAConfig = undefined
      if (commitSHA) {
         commitSHAConfig = String(commitSHA)
      }

      Sentry.init({
         app,
         dsn: String(import.meta.env.VITE_SENTRY_DSN),
         trackComponents: true,
         integrations: [
            Sentry.breadcrumbsIntegration(),
            Sentry.browserTracingIntegration({ router }),
            Sentry.contextLinesIntegration(),
            Sentry.replayIntegration(),
            Sentry.globalHandlersIntegration(),
            Sentry.linkedErrorsIntegration(),
            Sentry.sessionTimingIntegration(),
            Sentry.browserApiErrorsIntegration(),
         ],
         // Performance Monitoring
         tracesSampleRate: 1.0, //  Capture 100% of the transactions
         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
         tracePropagationTargets: [
            String(sentryBrowserDomain),
            String(sentryApiDomain),
         ],
         release: commitSHAConfig,
         environment: String(env),
         debug: env === "development",
         // Session Replay
         replaysSessionSampleRate: sentryReplaysSessionSampleRateFloat, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
         replaysOnErrorSampleRate: sentryReplaysOnErrorSampleRateFloat, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
      console.log("Sentry initialized")
   } else {
      console.log("Sentry not enabled")
   }
}
const {
   captureException,
   addBreadcrumb,
   setContext,
   setUser,
   setTags,
   captureMessage,
} = Sentry

function setupSessionMetricsStoreContext() {
   const overviewStore = useOverviewStore()
   const sessionMetricsContext = new Map<string, Primitive>()
   sessionMetricsContext.set("sessionMetricsLoading", overviewStore.loading)
   sessionMetricsContext.set(
      "sessionMetricsStartDate",
      overviewStore.start_date,
   )
   sessionMetricsContext.set("sessionMetricsEndDate", overviewStore.end_date)
   setContext("sessionMetricsStore", sessionMetricsContext)
}

function setupProjectStoreContext() {
   const projectStore = useProjectStore()
   const projectContext = new Map<string, unknown>()
   projectContext.set("projectId", projectStore.projectId)
   projectContext.set("projectName", projectStore.projectName)
   projectContext.set("projects", projectStore.projects)
   projectContext.set("variables", projectStore.variables)
   setContext("projectStore", projectContext)
}

async function setupClerkTags() {
   const org = await getOrg()
   const orgId = org?.id
   const activeSub = await hasActiveStripeSubscription()
   const tags: { [key: string]: Primitive } = {} // Convert Map to object

   tags["clerkOrgId"] = orgId
   tags["clerkOrgName"] = org?.name
   tags["clerkHasActiveSubscription"] = activeSub

   setTags(tags)
}

async function setupUser() {
   const u = await getUser()
   if (!u) {
      return
   }
   const user = new Map<string, Primitive>()
   user.set("email", u.email)
   user.set("id", u.id)
   user.set("full-name", u.fullName)

   setUser(user)
}

async function setupClerkContext() {
   const clerkContext = new Map<string, Primitive>()
   const org = await getOrg()
   const orgId = org?.id
   const orgSlug = org?.slug
   const activeSub = await hasActiveStripeSubscription()
   const user = await getUser()

   clerkContext.set("clerkOrgSlug", orgSlug)
   clerkContext.set("clerkUserId", user?.id)
   clerkContext.set("clerkOrgId", orgId)
   clerkContext.set("clerkOrgName", org?.name)
   clerkContext.set("clerkHasActiveSubscription", activeSub)
   setContext("clerk", clerkContext)
}

const setSentryContext = async (): Promise<void> => {
   // tags are searchable in Sentry
   await setupClerkTags()

   // context provides extra information viewable on the error event
   await setupClerkContext()
   setupSessionMetricsStoreContext()
   setupProjectStoreContext()

   // user provides information about the user who experienced the error
   await setupUser()
}

export {
   initSentry,
   Sentry,
   captureException,
   addBreadcrumb,
   setSentryContext,
   captureMessage,
}
