<template>
   <div id="home-main-container" class="bg-white q-pa-md">
      <!-- Header Section -->
      <PageHeader
         v-model="selectedUtmSource"
         title="Combination Explorer"
         :start-date="startDate"
         :end-date="endDate"
         @update:dates="handleDateChange"
      />
      <InstallInstructions2 v-if="shouldShowInstructions" />
      <div v-else id="main-container">
         <!-- Installation Instructions or Stacked Graphs -->
         <StackedGraphs2
            :time-series="overviewStore.timeSeries ?? null"
            :conversion-time-series="overviewStore.conversionTimeSeries ?? null"
         />
         <CombinationsTable
            :top-n="overviewStore?.topN"
            :stats="undefined"
            :days-back="daysBack"
            :has-variants="!!overviewStore.topN"
            @get-combos="handleGetCombos"
         />
      </div>
      <InstallInstructions v-if="shouldShowInstructions" />
   </div>
</template>
<script setup lang="ts">
// Imports
import { useQuasar } from "quasar"
import { useProjectStore } from "../stores"
import { useOverviewStore } from "@stores/overviewStore"
import { onMounted, watch, ref, computed } from "vue"
import InstallInstructions from "@components/installation-instructions/InstallInstructions.vue"
import StackedGraphs2 from "@components/combination-explorer/StackedGraphs.vue"
import InstallInstructions2 from "@components/installation-instructions/InstallInstructions2.vue"
import PageHeader from "@/components/common/PageHeader.vue"
import CombinationsTable from "@components/combinations-table/CombinationsTable.vue"

// Utilities
const $q = useQuasar()

// Stores
const projectStore = useProjectStore()
const overviewStore = useOverviewStore()

// State
const startDate = ref(
   overviewStore.start_date ??
      (() => {
         const date = new Date()
         date.setDate(date.getDate() - 30)
         return Math.floor(date.getTime())
      })(),
)

const endDate = ref(
   overviewStore.end_date ??
      (() => {
         const date = new Date()
         date.setDate(date.getDate() + 1)
         return Math.floor(date.getTime())
      })(),
)

const selectedUtmSource = ref<string | null>(null)

import { TablePagination } from "@components/combinations-table/CombinationsTable.vue"

const pagination = ref<TablePagination>({
   page: 1,
   rowsPerPage: 10,
   sortBy: "traffic_pct",
   descending: true,
})

// Computed
const shouldShowInstructions = computed(() => {
   const hasData =
      !!overviewStore.timeSeries ||
      !!overviewStore.conversionTimeSeries ||
      !!overviewStore.topN
   return !overviewStore.loading && !hasData
})

const daysBack = computed(() => {
   return Math.floor((endDate.value - startDate.value) / (1000 * 60 * 60 * 24))
})

// Handlers
const handleDateChange = async (newStartDate: number, newEndDate: number) => {
   startDate.value = newStartDate
   endDate.value = newEndDate
   await handleGetComboExplorer({
      ...pagination.value,
      page: 1,
   })
}

const handleGetComboExplorer = async (newPagination: TablePagination) => {
   pagination.value = newPagination
   const startAt = newPagination.rowsPerPage * (newPagination.page - 1)

   await overviewStore.fetchCombinationExplorer(
      startDate.value,
      endDate.value,
      selectedUtmSource.value,
      {
         startAt,
         rowsPerPage: newPagination.rowsPerPage,
         sortBy: newPagination.sortBy,
         descending: newPagination.descending,
      },
   )
}

const handleGetCombos = async (newPagination: TablePagination) => {
   pagination.value = newPagination
   const startAt = newPagination.rowsPerPage * (newPagination.page - 1)

   await overviewStore.fetchTopN(
      startDate.value,
      endDate.value,
      selectedUtmSource.value,
      {
         startAt,
         rowsPerPage: newPagination.rowsPerPage,
         sortBy: newPagination.sortBy,
         descending: newPagination.descending,
      },
   )
}

// Lifecycle Hooks
onMounted(async () => {
   // Fetch variables for CombinationDetailsDialog
   await projectStore.getActiveOrgProjects()
   await projectStore.getVariablesByProjectId()

   // Fetch combination explorer data
   await handleGetComboExplorer({
      ...pagination.value,
      page: 1,
   })
})

// Watch for project changes to refresh data
watch(
   () => projectStore.projectId,
   async (newProjectId) => {
      if (newProjectId) {
         overviewStore.clearState()
         await handleGetComboExplorer({
            ...pagination.value,
            page: 1,
         })
      }
   },
)

// Watch for UTM source changes
watch(selectedUtmSource, async () => {
   await handleGetComboExplorer({
      ...pagination.value,
      page: 1,
   })
})

// Subscriptions
overviewStore.$subscribe((mutation, state) => {
   // Only show loading if we're loading AND don't have data yet
   if (
      state.loading &&
      !state.conversionTimeSeries &&
      !state.timeSeries &&
      !state.topN
   ) {
      $q.loading.show()
   } else {
      $q.loading.hide()
   }
})
</script>
<style lang="scss" scoped>
#main-container {
   width: 100%;
}
#kpi-container {
   display: flex;
   align-items: center;
   gap: 2em;
   width: 100%;
   padding-top: 1em;
   padding-bottom: 1em;
}
.kpi-card {
   width: 100%;
}
#graphs-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 2em;
}
.graph-card {
   width: 100%;
}
#chips-container {
   display: flex;
   gap: 1em;
}
#conversion-rate-filter-container {
   display: flex;
   gap: 1em;
   // border: 1px solid rgb(160, 160, 160);
   // border-radius: 5px;
   padding: 1em 2em;
}
@media (max-width: 1400px) {
   #dashboard-title {
      margin-bottom: 0.5em;
   }
   .graph-card {
      width: 100%;
   }
   #kpi-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
   #graphs-container {
      flex-direction: column;
   }
   #dropdown-container {
      margin-bottom: 1em;
   }
   #chips-container {
      width: 100%;
   }
}
#home-main-container {
   min-height: 100vh;
}
#intro-paragraph {
   max-width: 800px;
}
</style>
