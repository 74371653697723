<template>
   <div v-if="orgRef" class="bg-white q-pa-md">
      <h1 class="text-h3 text-left">{{ orgRef.name }} Home</h1>
      <p id="org-id"><span>Organization Id: </span>{{ orgRef.id }}</p>
      <div id="org-profile-container">
         <OrganizationProfile />
      </div>
      <div id="subscription-container">
         <ManageSubscription />
      </div>
   </div>
</template>
<script setup lang="ts">
import { OrganizationProfile } from "@clerk/vue"
import { ManageSubscription } from "@/components"
import { BasicOrg, getOrg } from "@/services/clerk"
import { Ref, onMounted, ref } from "vue"
const orgRef: Ref<BasicOrg | null> = ref(null)
onMounted(async () => {
   const org = await getOrg()
   if (!org) {
      throw new Error("Org is undefined")
   }
   orgRef.value = org
})
</script>
<style lang="scss">
#org-profile-container {
   .cl-cardBox {
      box-shadow: none;
   }
}
#org-id {
   width: 100%;
   text-align: left;
   span {
      font-weight: bold;
   }
}
</style>
