import { defineStore } from "pinia"
import { adminBackendClient } from "@/services/adminBackendClient"
import { useProjectStore } from "./projectStore"
import { Overview } from "@/types/overview"
import {
   CombinationExplorer,
   TimeSeries,
   ConversionTimeSeries,
} from "@/types/combination-explorer"
import {
   HourOfDay,
   TopN,
   VariableExplorer,
   VariableSnapshot,
} from "@/types/dashboard"
import { collectError } from "@utils/errors"
import { toast } from "vue3-toastify"
import overview from "@/mocks/overview"
import combinationExplorer from "@/mocks/combination-explorer"
import variableExplorer from "@/mocks/variable-explorer"

interface OverviewState {
   error: string | null
   start_date: number | null
   end_date: number | null
   overview: Overview | null
   loading: boolean
   topN?: TopN
   timeSeries?: TimeSeries
   conversionTimeSeries?: ConversionTimeSeries
   variableSnapshot?: VariableSnapshot
   hourOfDay?: HourOfDay[]
}

export const useOverviewStore = defineStore("overviewStore", {
   state: (): OverviewState => ({
      error: null,
      start_date: null,
      end_date: null,
      overview: null,
      loading: false,
      topN: undefined,
      timeSeries: undefined,
      conversionTimeSeries: undefined,
      variableSnapshot: undefined,
      hourOfDay: undefined,
   }),

   getters: {
      dateRange: (state) => ({
         start: state.start_date,
         end: state.end_date,
      }),
   },

   actions: {
      async callForOverview(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
      ) {
         const projectStore = useProjectStore()
         const params: Record<string, unknown> = {
            start_date: Math.floor(startDate / 1000), // API uses seconds, JS/TS use milliseconds
            end_date: Math.floor(endDate / 1000), // API uses seconds, JS/TS use milliseconds
         }

         if (utmSource !== null && utmSource != "") {
            params.utm_source = utmSource
         }

         return await adminBackendClient.get<Overview>(
            `/v2/projects/${projectStore.projectId}/overview`,
            { params },
         )
      },
      async fetchOverview(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
      ) {
         const projectStore = useProjectStore()
         if (!projectStore.projectId) {
            throw new Error("No active project selected")
         }

         this.error = null
         this.loading = true
         this.overview = null

         try {
            let responseData: Overview | null = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               // simulate network delay
               await new Promise((resolve) =>
                  setTimeout(resolve, Math.random() * 1000 + 250),
               )
               responseData = overview
            } else {
               const response = await this.callForOverview(
                  startDate,
                  endDate,
                  utmSource,
               )
               responseData = response.data
            }

            // Update all fields atomically to maintain reactivity
            this.$patch({
               overview: responseData,
               start_date: startDate,
               end_date: endDate,
               error: null,
            })
         } catch (error) {
            // Collect error and retry once (temporary fix)
            await collectError(error as Error)
            try {
               const response = await this.callForOverview(
                  startDate,
                  endDate,
                  utmSource,
               )
               // Update all fields atomically to maintain reactivity
               this.$patch({
                  overview: response.data,
                  start_date: startDate,
                  end_date: endDate,
                  error: null,
               })
            } catch (error) {
               const message = "Failed to fetch overview data"
               this.error = message
               toast.error(message)
            }
            return null
         } finally {
            this.loading = false
         }
      },

      async callForTopN(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
         pagination?: {
            startAt: number
            rowsPerPage: number
            sortBy: string
            descending: boolean
         },
      ) {
         const projectStore = useProjectStore()
         const params: Record<string, unknown> = {
            start_date: Math.floor(startDate / 1000),
            end_date: Math.floor(endDate / 1000),
         }

         if (utmSource !== null && utmSource !== "") {
            params.utm_source = utmSource
         }

         if (pagination) {
            params.start_at = pagination.startAt
            params.rows_per_page = pagination.rowsPerPage
            params.sort_by = pagination.sortBy
            params.descending = pagination.descending
         }

         return await adminBackendClient.get<TopN>(
            `/v2/projects/${projectStore.projectId}/top-n`,
            { params },
         )
      },

      async fetchTopN(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
         pagination?: {
            startAt: number
            rowsPerPage: number
            sortBy: string
            descending: boolean
         },
      ) {
         const projectStore = useProjectStore()
         if (!projectStore.projectId) {
            throw new Error("No active project selected")
         }

         this.error = null
         this.loading = true
         this.topN = undefined

         try {
            const response = await this.callForTopN(
               startDate,
               endDate,
               utmSource,
               pagination,
            )
            this.$patch({
               topN: response.data,
               loading: false,
            })
         } catch (error) {
            const message = "Failed to fetch top-n data"
            this.$patch({
               error: message,
               loading: false,
            })
            await collectError(error as Error)
            toast.error(message)
         }
      },

      async callForCombinationExplorer(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
         pagination?: {
            startAt: number
            rowsPerPage: number
            sortBy: string
            descending: boolean
         },
      ) {
         const projectStore = useProjectStore()
         const params: Record<string, unknown> = {
            start_date: Math.floor(startDate / 1000),
            end_date: Math.floor(endDate / 1000),
         }

         if (utmSource !== null && utmSource !== "") {
            params.utm_source = utmSource
         }

         if (pagination) {
            params.start_at = pagination.startAt
            params.rows_per_page = pagination.rowsPerPage
            params.sort_by = pagination.sortBy
            params.descending = pagination.descending
         }

         return await adminBackendClient.get<CombinationExplorer>(
            `/v2/projects/${projectStore.projectId}/combination-explorer`,
            { params },
         )
      },

      async fetchCombinationExplorer(
         startDate: number,
         endDate: number,
         utmSource: string | null = null,
         pagination?: {
            startAt: number
            rowsPerPage: number
            sortBy: string
            descending: boolean
         },
      ) {
         const projectStore = useProjectStore()
         if (!projectStore.projectId) {
            throw new Error("No active project selected")
         }

         this.error = null
         this.loading = true
         this.topN = undefined
         this.timeSeries = undefined
         this.conversionTimeSeries = undefined

         try {
            let responseData: CombinationExplorer | null = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               // simulate network delay
               await new Promise((resolve) =>
                  setTimeout(resolve, Math.random() * 1000 + 1000),
               )
               responseData = combinationExplorer
            } else {
               const result = await this.callForCombinationExplorer(
                  startDate,
                  endDate,
                  utmSource,
                  pagination,
               )
               responseData = result.data
            }

            // Update all fields atomically to maintain reactivity
            this.$patch({
               topN: responseData?.top_n,
               timeSeries: responseData?.time_series,
               conversionTimeSeries: responseData?.conversion_time_series,
               start_date: startDate,
               end_date: endDate,
               error: null,
               loading: false,
            })
         } catch (error) {
            // Collect error and retry once (temporary fix)
            await collectError(error as Error)
            try {
               const result = await this.callForCombinationExplorer(
                  startDate,
                  endDate,
                  utmSource,
                  pagination,
               )
               // Update all fields atomically to maintain reactivity
               this.$patch({
                  topN: result.data?.top_n,
                  timeSeries: result.data?.time_series,
                  conversionTimeSeries: result.data?.conversion_time_series,
                  start_date: startDate,
                  end_date: endDate,
                  error: null,
                  loading: false,
               })
            } catch (retryError) {
               const message = "Failed to fetch combination explorer data"
               this.$patch({
                  error: message,
                  loading: false,
               })
               await collectError(retryError as Error)
               toast.error(message)
            }
         }
      },

      async callForVariableExplorer(
         startDate: number,
         endDate: number,
         variableId: number,
         utmSource: string | null = null,
      ) {
         const projectStore = useProjectStore()
         const params: Record<string, unknown> = {
            start_date: Math.floor(startDate / 1000),
            end_date: Math.floor(endDate / 1000),
            variable_id: variableId,
         }

         if (utmSource !== null && utmSource != "") {
            params.utm_source = utmSource
         }

         return await adminBackendClient.get<VariableExplorer>(
            `/v2/projects/${projectStore.projectId}/variable-explorer`,
            { params },
         )
      },

      async fetchVariableExplorer(
         startDate: number,
         endDate: number,
         variableId: number,
         utmSource: string | null = null,
      ) {
         const projectStore = useProjectStore()
         if (!projectStore.projectId) {
            throw new Error("No active project selected")
         }

         this.error = null
         this.loading = true
         this.variableSnapshot = undefined
         this.hourOfDay = undefined

         try {
            let responseData: VariableExplorer | null = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               // simulate network delay
               await new Promise((resolve) =>
                  setTimeout(resolve, Math.random() * 1000 + 250),
               )
               responseData = variableExplorer
            } else {
               const response = await this.callForVariableExplorer(
                  startDate,
                  endDate,
                  variableId,
                  utmSource,
               )
               responseData = response.data
            }

            // Update all fields atomically to maintain reactivity
            this.$patch({
               variableSnapshot: responseData.variable_snapshot,
               hourOfDay: responseData.hour_of_day,
               error: null,
            })
         } catch (error) {
            // Collect error and retry once (temporary fix)
            await collectError(error as Error)
            try {
               const response = await this.callForVariableExplorer(
                  startDate,
                  endDate,
                  variableId,
                  utmSource,
               )
               // Update all fields atomically to maintain reactivity
               this.$patch({
                  variableSnapshot: response.data.variable_snapshot,
                  hourOfDay: response.data.hour_of_day,
                  error: null,
               })
            } catch (error) {
               const message = "Failed to fetch variable explorer data"
               this.error = message
               toast.error(message)
            }
            return null
         } finally {
            this.loading = false
         }
      },

      async loadUtmSources(startDate: number, endDate: number) {
         try {
            let response = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = { data: ["google", "facebook", "instagram", "bing"] }
            } else {
               const projectStore = useProjectStore()
               if (!projectStore.projectId) {
                  throw new Error("No active project selected")
               }
               response = await adminBackendClient.get(
                  `/v2/projects/${projectStore.projectId}/field-values`,
                  {
                     params: {
                        field_name: "utm_source",
                        start_date: Math.floor(startDate / 1000), // API uses seconds, JS/TS use milliseconds
                        end_date: Math.floor(endDate / 1000), // API uses seconds, JS/TS use milliseconds
                     },
                  },
               )
            }
            // utmSourceOptions.value = response.data
            return response.data as string[]
         } catch (error) {
            console.error("Failed to load UTM sources:", error)
            return [] as string[]
         }
      },

      async loadUtmSourcesAc(
         startDate: number,
         endDate: number,
         prefix: string,
      ) {
         try {
            let response = null
            if (import.meta.env.VITE_MOCK_SESSION_STATS == "true") {
               response = {
                  data: ["google", "facebook", "instagram", "bing"].filter(
                     (v) => v.toLocaleLowerCase().indexOf(prefix) > -1,
                  ),
               }
            } else {
               const projectStore = useProjectStore()
               if (!projectStore.projectId) {
                  throw new Error("No active project selected")
               }
               response = await adminBackendClient.get(
                  `/v2/projects/${projectStore.projectId}/field-suggestions`,
                  {
                     params: {
                        field_name: "utm_source",
                        start_date: Math.floor(startDate / 1000), // API uses seconds, JS/TS use milliseconds
                        end_date: Math.floor(endDate / 1000), // API uses seconds, JS/TS use milliseconds
                        prefix: prefix,
                     },
                  },
               )
            }
            // utmSourceOptions.value = response.data
            return response.data as string[]
         } catch (error) {
            console.error("Failed to load UTM sources:", error)
            return [] as string[]
         }
      },

      clearState() {
         this.error = null
         this.start_date = null
         this.end_date = null
         this.overview = null
         this.topN = undefined
         this.timeSeries = undefined
         this.conversionTimeSeries = undefined
         this.loading = false
         this.variableSnapshot = undefined
         this.hourOfDay = undefined
      },
   },
   persist: true,
})
