<template>
   <div id="container">
      <QCard id="card">
         <QCardSection class="bg-green text-white text-h2 text-bold q-pa-lg"
            ><span>Thank You</span></QCardSection
         >
         <QCardSection class="q-pa-xl"
            >You will receive an email with login instructions shortly. If you
            need help, please contact
            <a href="mailto:support@ezbot.ai">support@ezbot.ai</a></QCardSection
         >
      </QCard>
      <Teleport v-if="stageWidth && stageHeight" to="body">
         <div id="confetti">
            <ConfettiExplosion
               :stage-height="stageHeight"
               :stage-width="stageWidth"
            />
         </div>
      </Teleport>
   </div>
</template>

<script setup lang="ts">
import { trackEvent } from "@/analytics/trackEvent"
import { onMounted, ref } from "vue"
import ConfettiExplosion from "vue-confetti-explosion"

const stageHeight = ref<number | null>(null)
const stageWidth = ref<number | null>(null)

onMounted(() => {
   trackEvent("stripe_subscription_started")
   stageHeight.value = window.innerHeight
   stageWidth.value = window.innerWidth
})
</script>

<style lang="scss" scoped>
#card {
   width: 100%;
   max-width: 600px;
}
#container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background-color: #f5f5f5;
   background-image: linear-gradient(to top right, #007bff, #00aaff);
}
#confetti {
   position: absolute;
   width: 50%;
   left: 50%;
   top: 25%;
}
</style>
