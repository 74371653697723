import { defineStore } from "pinia"

type AppState = {
   counter: number
   leftDrawerOpen: boolean
   globalStartDate: number
   globalEndDate: number
}

export const useAppState = defineStore("appState", {
   state: (): AppState => {
      return {
         counter: 0,
         leftDrawerOpen: false,
         globalStartDate: (() => {
            const date = new Date()
            date.setDate(date.getDate() - 30)
            return Math.floor(date.getTime())
         })(),
         globalEndDate: (() => {
            const date = new Date()
            date.setDate(date.getDate() + 1)
            return Math.floor(date.getTime())
         })(),
      }
   },
   actions: {
      updateDateRange(startDate: number, endDate: number) {
         this.globalStartDate = startDate
         this.globalEndDate = endDate
      },
   },
   persist: true,
})
